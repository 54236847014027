import "./estilo.css"
import { Layout } from "antd"
import Lottie from 'react-lottie';
import * as animationData from '../../animaciones/jump.json'

const Cargando=()=>{
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return <Layout style={{height:'100vh',width:'100vw',overflow:'hidden',position:'fixed',zIndex:999999,top:0,left:0}}>
        <Lottie options={defaultOptions} height={400} width={400} style={{position:'fixed',top:'50%',left:'50%',marginLeft:-200,marginTop:-200}}/>
    </Layout>
}

export default Cargando