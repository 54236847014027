import { useCallback, useEffect, useRef, useState } from "react";
import { CloudUploadOutlined, DashboardOutlined, LineChartOutlined, PlayCircleOutlined, PlusOutlined, SaveOutlined, StopOutlined, VideoCameraOutlined } from "@ant-design/icons"
import { Badge, Button, Card, Flex, Layout, Form, Progress, Segmented, Slider, Spin, Table, Tabs, Typography, notification, theme, Select } from "antd"
//import Webcam from "react-webcam";
//import { runCMJ } from "../cmj";
import { ACELERACION_GRAVEDAD, EVENT_BLE } from "../../../../../Constantes";
//import data from "../data";
import ReactECharts from 'echarts-for-react';
import { blobToBase64 } from "../../../../../Util";
import API from "../../../../../API";
import TextArea from "antd/es/input/TextArea";
//import VideoFases from "../../../../../Componentes/VideoFases";
import useBle from "../../../../../Hooks/Ble";
import { useNavigate } from "react-router-dom";
const { useToken } = theme;

const { Header } = Layout
//let ble_data = [...data]


// const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user"
// };

const Grafica = ({ opciones_grafica }) => {
    return <div style={{ height: 600 }}>
        <ReactECharts lazyUpdate={true} theme={'dark2'} option={opciones_grafica} style={{ width: '100%', height: '100%' }} />
    </div>
}



const Rep = ({ atleta }) => {
    const navigate = useNavigate()
    const [seed, setSeed] = useState(null)
    const { token } = useToken()
    //const webcamRef = useRef(null);
    //const video_blob = useRef([])
    const datos_brutos = useRef([])
    const [fases, setFases] = useState([])
    const [parametros, setParametros] = useState([])
    const [frames, setFrames] = useState([])
    const [cargando_frames, setCargandoFrames] = useState(false)
    const media_recorder = useRef(null)
    const [sistema_de_pesos_y_tiempos, setSistemaDePesoTiempo] = useState([])
    const [grabando, setGrabando] = useState(false)
    const ref_grabando = useRef(false)
    const [url_video, setURLVideo] = useState(null)
    const [cargando, setCargando] = useState(false)

    const tiempo_inicio_grabacion = useRef(null)

    const { resetearBalanza } = useBle()

    const tiempo_inicio = useRef(0)

    const opciones_grafica = {
        type: 'line',
        legend: {

        },
        tooltip: {
            trigger: 'none',
            axisPointer: {
                type: 'cross'
            }
        },
        xAxis: {
            type: 'category',
            name: 'Tiempo (s)',
            nameLocation: 'middle',
            axisLabel: {
                formatter: '{value}',
                align: 'center'
            },
            data: sistema_de_pesos_y_tiempos.map(s => s.tiempo.toFixed(1)),
        },
        yAxis: {
            type: 'value',
            name: 'Fuerza (N)',
            boundaryGap: [0, '30%'],
            nameLocation: 'middle',
            nameGap: 50
        },
        series: [
            {
                name: 'Píe izquierdo',
                type: 'line',
                symbol: 'none',
                data: sistema_de_pesos_y_tiempos.map(s => s.peso.izq)

            },
            {
                name: 'Píe derecho',
                type: 'line',
                symbol: 'none',
                data: sistema_de_pesos_y_tiempos.map(s => s.peso.der)

            },
            {
                name: 'Combinado',
                type: 'line',
                backgroundColor: '#F39C12',
                data: sistema_de_pesos_y_tiempos.map(d => d.peso.combinada),
                markArea: {
                    itemStyle: {
                        opacity: .5,

                    },
                    label: {
                        rotate: 45,
                        position: 'insideTopLeft',
                        color: 'whitesmoke'
                    },
                    data: fases.map(f => ([
                        {
                            name: f.nombre,
                            xAxis: f.inicio,
                            itemStyle: {
                                color: f.color,
                                opacity: .5
                            }
                        },
                        {
                            xAxis: f.fin
                        }
                    ]))

                }
            }
        ]
    }

    // const simulatBle = () => {
    //     console.log("Simular ble")
    //     ble_data = [...data]
    //     tiempo_inicio.current = Date.now()
    //     setSistemaDePesoTiempo([])
    //     datos_brutos.current.push(["I[Kg]", "D[Kg]", "epoch[S]"].join(" "))
    //     setInterval(() => {
    //         if (ble_data.length) {
    //             const dato = ble_data.shift()
    //             setSistemaDePesoTiempo(prev => {
    //                 datos_brutos.current.push([dato['I[Kg]'], dato['D[Kg]'], dato['epoch[S]']].join(" "))
    //                 const tiempo_anterior = prev.length ? prev[prev.length - 1].anterior : 0
    //                 const acomulado = prev.length ? prev[prev.length - 1].tiempo : 0
    //                 const tiempo_transcurrido = prev.length ? parseFloat(dato['epoch[S]']) - tiempo_anterior : 0
    //                 return [...prev, {
    //                     anterior: parseFloat(dato['epoch[S]']),
    //                     tiempo: tiempo_transcurrido + acomulado,
    //                     masa: {
    //                         combinada: parseFloat(dato['I[Kg]']) + parseFloat(dato['D[Kg]']),
    //                         izq: parseFloat(dato['I[Kg]']),
    //                         der: parseFloat(dato['D[Kg]'])
    //                     },
    //                     peso: {
    //                         combinada: (parseFloat(dato['I[Kg]']) + parseFloat(dato['D[Kg]'])) * ACELERACION_GRAVEDAD,
    //                         izq: parseFloat(dato['I[Kg]']) * ACELERACION_GRAVEDAD,
    //                         der: parseFloat(dato['D[Kg]']) * ACELERACION_GRAVEDAD
    //                     }
    //                 }]
    //             })

    //         }
    //     })
    // }

    function onBle({ detail }) {

        if (detail.length && ref_grabando.current === true) {
            console.log('Ble...')
            setSistemaDePesoTiempo(prev => {
                const dato = detail
                datos_brutos.current.push([dato[0], dato[1], dato[2]].join(" "))
                const tiempo_anterior = prev.length ? prev[prev.length - 1].anterior : 0
                const acomulado = prev.length ? prev[prev.length - 1].tiempo : 0
                const tiempo_transcurrido = prev.length ? parseFloat(dato[2]) - tiempo_anterior : 0
                return [...prev, {
                    anterior: parseFloat(dato[2]),
                    tiempo: tiempo_transcurrido + acomulado,
                    masa: {
                        combinada: parseFloat(dato[0]) + parseFloat(dato[1]),
                        izq: parseFloat(dato[0]),
                        der: parseFloat(dato[1])
                    },
                    peso: {
                        combinada: (parseFloat(dato[0]) + parseFloat(dato[1])) * ACELERACION_GRAVEDAD,
                        izq: parseFloat(dato[0]) * ACELERACION_GRAVEDAD,
                        der: parseFloat(dato[1]) * ACELERACION_GRAVEDAD
                    }
                }]
            })
        }
    }

    const iniciar = async () => {
        setFases([])
        setFrames([])
        setSistemaDePesoTiempo([])
        //video_blob.current = []
        datos_brutos.current = []
        await resetearBalanza()
        // if (webcamRef?.current) {
        //     media_recorder.current = new MediaRecorder(webcamRef?.current?.stream, { mimeType: 'video/webm' });
        //     media_recorder.current.addEventListener('dataavailable', function (e) {
        //         video_blob.current.push(e.data);
        //     });
        //     media_recorder.current.start()
        // }
        
        setGrabando(true)
        ref_grabando.current = true



    }

    async function detener() {
        setGrabando(false)
        ref_grabando.current = false
        console.log(media_recorder.current)
        try {
            if (media_recorder.current) {
                console.log("Detener grabacion")
                media_recorder.current.stop()
                await esperar(800)
                //let video_local = URL.createObjectURL(new Blob(video_blob.current, { type: 'video/webm' }));
                //document.getElementById(seed + "video").src = video_local
                //console.log({ video_local })
                //setURLVideo(video_local)
            }
        } catch (err) {
            console.log("Error deteniendo grabacion ", err)
        }

        try {
            //await calcularParametros()
        } catch (error) {
            notification.error({ description: 'Descarte la prueba no se obtuvieron datos validos' })
        }
    }

    const esperar = (t) => {
        return new Promise((resolve) => setTimeout(resolve, t))
    }

    // const calcularParametros = async () => {
    //     try {
    //         setCargandoFrames(true)
    //         const { fases: fases_calculadas, parametros: parametros_calculados } = await runCMJ(sistema_de_pesos_y_tiempos)
    //         setParametros(parametros_calculados)
    //         setFases(fases_calculadas)
    //         setCargandoFrames(false)
    //         notification.success({ description: 'Parametros calculados y video analizado' })
    //     } catch (err) {
    //         console.log(err)
    //         notification.error({ description: err.toString() })
    //     } finally {
    //         setCargandoFrames(false)
    //     }
    // }

    const tabs = [
        {
            label: 'Gráfica',
            key: 1,
            icon: <LineChartOutlined />,
            children: <Grafica opciones_grafica={opciones_grafica} />
        // },
        // {
        //     label: 'Parametros',
        //     key: 2,
        //     icon: <DashboardOutlined />,
        //     children: <div style={{ paddingRight: 16, paddingTop: 16 }}>
        //         <Flex gap="middle" justify="space-between">
        //             {
        //                 fases?.map((f, i) => <Card key={i} style={{ flex: 1 }}>
        //                     <Typography.Title level={5}>{f.nombre}</Typography.Title>
        //                     <Typography.Text>{f?.tiempo?.duracion.toFixed(1)} Seg.</Typography.Text>
        //                 </Card>)
        //             }
        //         </Flex>
        //         <br></br>
        //         <Table dataSource={parametros} pagination={false} scroll={{ y: 600 }}>
        //             <Table.Column title="Parametro" dataIndex={'nombre'} />
        //             <Table.Column title="Valor" dataIndex={'valor'} />
        //         </Table>
        //     </div>
        // },
        // {
            //label: 'Video',
            //key: 3,
            //icon: <VideoCameraOutlined />,
            //children: <VideoFases url_video={url_video} fases={fases} sistema_de_pesos_y_tiempos={sistema_de_pesos_y_tiempos} seed={Date.now()} />
        }
    ]

    const guardar = async (extra) => {
        try {
            setCargando(true)
            //const video = await blobToBase64(new Blob(video_blob.current, { type: 'video/webm' }))
            const data = {
                ...extra,
                atleta: atleta,
                datos_brutos: datos_brutos.current,
                //video,
                sistema_de_pesos_y_tiempos,
                //fases,
                //parametros
                tipoPrueba: "Nordic",
            }
            await API.post('prueba', data)
            notification.success({ description: 'Prueba guardada' })
            //navigate('/pruebas')
        } catch (err) {
            notification.error({
                description: err?.response?.data?.mensaje || err.toString()
            })
        } finally {
            setCargando(false)
        }

    }


    useEffect(() => {
        setSeed(Date.now())
        document.addEventListener(EVENT_BLE, onBle)

        return () => {
            console.log("remover e")
            document.removeEventListener(EVENT_BLE, onBle)
        }
    }, [])


    return <>
        <Flex justify="space-between" style={{ flex: 1 }} >
            <div style={{ flex: 1 }}>
                <Tabs style={{ flex: 1 }} tabPosition="left" items={tabs} />
            </div>

            <Flex style={{ width: '30%' }} vertical justify="flex-start">
                {/*<Webcam ref={webcamRef} audio={false} style={{ maxWidth: '100%' }}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints} />*/}
                <br></br>
                {/* <canvas id={`${seed}canvas`} style={{ display: 'none' }}></canvas>
                <video id={`${seed}video`} style={{ display: 'none' }} autoPlay width={400}></video> */}
                <Flex vertical align="center">
                    {!grabando && !sistema_de_pesos_y_tiempos.length ? <Flex vertical gap="middle" align="center">
                        <PlayCircleOutlined style={{ fontSize: 72, color: token.colorPrimary }} onClick={iniciar} />
                        <Typography.Text style={{ textAlign: 'center' }}>Iniciar prueba</Typography.Text>
                    </Flex> : null}

                    {grabando ? <Flex vertical gap="middle" align="center">
                        <StopOutlined style={{ fontSize: 72, color: token.colorPrimary }} onClick={detener} />
                        <Typography.Text style={{ textAlign: 'center' }}>Detener prueba</Typography.Text>
                    </Flex> : null}

                    {!grabando && sistema_de_pesos_y_tiempos.length && !cargando_frames ? <>
                        <Form onFinish={guardar} layout="vertical">
                            <Form.Item label="Momento" name="momento" rules={[{required:true,message:'Seleccione'}]}>
                                <Select options={[{label:'Único',value:'Único'},{label:'Pre',value:'Pre'},{label:'Post',value:'Post'},{label:'24H',value:'24H'},{label:'48H',value:'48H'},{label:'72H',value:'72H'},{label:'96H',value:'96H'}]}/>
                            </Form.Item>
                            <Form.Item label="Impresiones" name={'impresion'} rules={[{ required: true, message: 'Ingrese sus impresiones' }]}>
                                <TextArea></TextArea>
                            </Form.Item>
                            <Button type="primary" htmlType="submit" size="large" icon={<CloudUploadOutlined style={{}} />}>
                                Guardar esta prueba
                            </Button>
                        </Form>
                    </> : null}

                    {cargando_frames ? <Spin spinning={true}><Typography.Text>Calculando parametros</Typography.Text></Spin> : null}
                </Flex>


            </Flex>

        </Flex>
    </>
}

export default Rep