import { Navigate, useNavigate } from "react-router-dom"
import useSesion from "../../Hooks/Sesion"
import "./estilo.css"
import { Button, Card, Col, Form, Input, Row, Typography, notification } from "antd"
import { useState } from "react"
import API from "../../API"

const Login=()=>{
    const navigate=useNavigate()
    const {usuario,login} = useSesion()
    const [cargando,setCargando] = useState(false)

    const acceder = async (payload)=>{
        
        try{
            setCargando(true)
            const {data} = await API.post('usuario/login',payload)
            login({
                token:data.token,
                redirigir:true
            })
        }catch(err){
            console.log('No entramos');
            console.log(err.response)
            setCargando(true)
            notification.error({description:err?.response?.data?.mensaje || err.toString()})
        }finally{
            setCargando(false)
        }
    }

    if(usuario){
        return <Navigate replace to="/"/>
    }

    return <div  className="pagina-login">
    <Row justify={'center'}>
        <Col xs={24} sm={8} md={6}>
            <Typography.Title>SEFUM</Typography.Title>
        <Card style={{maxWidth:400}}>
            <Form layout="vertical" autoComplete={'false'} disabled={cargando} onFinish={acceder}>
                <Form.Item label="Usuario" name="usuario">
                    <Input/>
                </Form.Item>
                <Form.Item label="Contraseña" name="clave">
                    <Input.Password/>
                </Form.Item>
                <Button block htmlType="submit" loading={cargando}>Acceder</Button>
            </Form>
        </Card>
        </Col>
    </Row>
   
    </div>
}

export default Login