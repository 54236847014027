import { notification, Breadcrumb, Flex, Typography, Card, Table, theme, Button,Input,Form,Select} from "antd"

import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
    import ECharts from 'echarts-for-react';
    import ecStat from 'echarts-stat';
    import * as echarts from 'echarts';
import API, { BASE_URL } from "../../../API"
import { Header } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { runCMJ } from "../CMJ/Guardar/cmj";
//import VideoFases from "../../../Componentes/VideoFases";
import SeleccionarMuestra from "../../../Componentes/SeleccionarMuestra";
//import calcularParametros from "../CMJ/Guardar/Rep";
import { FilePdfFilled, DatabaseOutlined} from "@ant-design/icons";
import { CSVLink } from 'react-csv'; // Importar CSVLink
import CrearGrupoModalCuadrante from "./ModalCuadrante";
import CrearGrupoModalCuadranteParams from "./modalCuadranteParams";


const { useToken } = theme;

const PruebaCuadrante = () => {
    const { token } = useToken()
    const navigate = useNavigate()
    const { id } = useParams()
    const [doc, setDoc] = useState([])
    const [atleta, setAtleta] = useState([])
    var [parametrosT, setParametrosT] = useState(["parametro1","parametro2"])
    const [cargando, setCargando] = useState(false)
    const grafica = useRef(null)
    const [color_area, setColorArea] = useState('whitesmoke')

    const [newfases, setFases] = useState([])
    const [newparametros, setParametros] = useState([])
    const [inicioFases, setInicioFases] = useState(0)
    const [finFases, setFinFases] = useState(0)

    const [recalculando, setreCalculando] = useState(false)
    const [paramsAutomatic, setParamsAutomatic] = useState([true,true])
    const [table, setTable] = useState([[[0,0]]])
    const [abrir,setAbrir] = useState(false);
    const [abrirParams,setAbrirParams] = useState([false,0]);
    const [limitesEjes,setLimitesEjes] = useState([-1500,1500,-1500,1500])
    const optionsParametros = [
        {
            "nombre": 'Porcentaje fase descarga',
            "label": 'Porcentaje fase descarga',
            "value": "0"
        },
        {
            "nombre": "RSI",
            "label": "RSI",
            "value": "1"
        },
        {
            "nombre": "Tiempo en despegar",
            "label": "Tiempo en despegar",
            "value": "2"
        },
        {
            "nombre": "Fuerza promedio de frenado",
            "label": "Fuerza promedio de frenado",
            "value": "3"
        },
        {
            "nombre": "Fuerza promedio de frenado pierna izq.",
            "label": "Fuerza promedio de frenado pierna izq.",
            "value": "4"
        },
        {
            "nombre": "Fuerza promedio de frenado pierna der.",
            "label": "Fuerza promedio de frenado pierna der.",
            "value": "5"
        },
        {
            "nombre": "Fuerza pico de frenado pierna der.",
            "label": "Fuerza pico de frenado pierna der.",
            "value": "6"
        },
        {
            "nombre": "Fuerza pico de frenado pierna izq.",
            "label": "Fuerza pico de frenado pierna izq.",
            "value": "7"
        },
        {
            "nombre": "Potencia promedio de frenado",
            "label": "Potencia promedio de frenado",
            "value": "8"
        },
        {
            "nombre": "Velocidad promedio de frenado",
            "label": "Velocidad promedio de frenado",
            "value": "9"
        },
        {
            "nombre": "Fuerza promedio de frenado relativa",
            "label": "Fuerza promedio de frenado relativa",
            "value": "10"
        },
        {
            "nombre": "Potencia promedio de frenado relativa",
            "label": "Potencia promedio de frenado relativa",
            "value": "11"
        },
        {
            "nombre": "Impulso de frenado",
            "label": "Impulso de frenado",
            "value": "12"
        },
        {
            "nombre": "Duración frenado",
            "label": "Duración frenado",
            "value": "13"
        },
        {
            "nombre": "% duración frenado",
            "label": "% duración frenado",
            "value": "14"
        },
        {
            "nombre": "RFD",
            "label": "RFD",
            "value": "15"
        },
        {
            "nombre": "Fuerza pico de frenado",
            "label": "Fuerza pico de frenado",
            "value": "16"
        },
        {
            "nombre": "Potencia pico de frenado",
            "label": "Potencia pico de frenado",
            "value": "17"
        },
        {
            "nombre": "Velocidad pico de frenado",
            "label": "Velocidad pico de frenado",
            "value": "18"
        },
        {
            "nombre": "Fuerza pico relativa de frenado",
            "label": "Fuerza pico relativa de frenado",
            "value": "19"
        },
        {
            "nombre": "Potencia pico relativa de frenado",
            "label": "Potencia pico relativa de frenado",
            "value": "20"
        },
        {
            "nombre": "Impulso pico relativa de frenado",
            "label": "Impulso pico relativa de frenado",
            "value": "21"
        },
        {
            "nombre": "Promedio RDF de frenado pierna izq.",
            "label": "Promedio RDF de frenado pierna izq.",
            "value": "22"
        },
        {
            "nombre": "Promedio RDF de frenado pierna der.",
            "label": "Promedio RDF de frenado pierna der.",
            "value": "23"
        },
        {
            "nombre": "Impulso positivo",
            "label": "Impulso positivo",
            "value": "24"
        },
        {
            "nombre": "Profundidad de contramovimiento",
            "label": "Profundidad de contramovimiento",
            "value": "25"
        },
        {
            "nombre": "Fuerza a minimo desplazamiento",
            "label": "Fuerza a minimo desplazamiento",
            "value": "26"
        },
        {
            "nombre": "Relación de impulsos",
            "label": "Relación de impulsos",
            "value": "27"
        },
        {
            "nombre": "Fuerza relativa a minimo desplazamiento",
            "label": "Fuerza relativa a minimo desplazamiento",
            "value": "28"
        },
        {
            "nombre": "Rigidez de contramivimiento",
            "label": "Rigidez de contramivimiento",
            "value": "29"
        },
        {
            "nombre": "Fuerza promedio de propulsión",
            "label": "Fuerza promedio de propulsión",
            "value": "30"
        },
        {
            "nombre": "Fuerza promedio de propulsión izq",
            "label": "Fuerza promedio de propulsión izq",
            "value": "31"
        },
        {
            "nombre": "Fuerza promedio de propulsión der",
            "label": "Fuerza promedio de propulsión der",
            "value": "32"
        },
        {
            "nombre": "Potencia promedio de propulsión",
            "label": "Potencia promedio de propulsión",
            "value": "33"
        },
        {
            "nombre": "Velocidad promedio de propulsión",
            "label": "Velocidad promedio de propulsión",
            "value": "34"
        },
        {
            "nombre": "Fuerza promedio de propulsion relativa",
            "label": "Fuerza promedio de propulsion relativa",
            "value": "35"
        },
        {
            "nombre": "Potencia promedio de propulsión relativa",
            "label": "Potencia promedio de propulsión relativa",
            "value": "36"
        },
        {
            "nombre": "Fuerza pico de propulsión",
            "label": "Fuerza pico de propulsión",
            "value": "37"
        },
        {
            "nombre": "Potencia pico de propulsion",
            "label": "Potencia pico de propulsion",
            "value": "38"
        },
        {
            "nombre": "Fuerza pico relativa de propulsion",
            "label": "Fuerza pico relativa de propulsion",
            "value": "39"
        },
        {
            "nombre": "Potencia pico relativa de propulsion",
            "label": "Potencia pico relativa de propulsion",
            "value": "40"
        },
        {
            "nombre": "Impulso de propulsión",
            "label": "Impulso de propulsión",
            "value": "41"
        },
        {
            "nombre": "Fase propulsion duracion",
            "label": "Fase propulsion duracion",
            "value": "42"
        },
        {
            "nombre": "Fase propulsion duracion",
            "label": "Fase propulsion duracion",
            "value": "43"
        },
        {
            "nombre": "Impulso relativo de propulsión",
            "label": "Impulso relativo de propulsión",
            "value": "44"
        },
        {
            "nombre": "Fuerza promedio de propulsión izq",
            "label": "Fuerza promedio de propulsión izq",
            "value": "45"
        },
        {
            "nombre": "Fuerza promedio de propulsión der",
            "label": "Fuerza promedio de propulsión der",
            "value": "46"
        },
        {
            "nombre": "Fuerza propulsiva der en pico de fuerza combinado",
            "label": "Fuerza propulsiva der en pico de fuerza combinado",
            "value": "47"
        },
        {
            "nombre": "Fuerza propulsiva izq en pico de fuerza combinado",
            "label": "Fuerza propulsiva izq en pico de fuerza combinado",
            "value": "48"
        },
        {
            "nombre": "Velocidad de despegue",
            "label": "Velocidad de despegue",
            "value": "49"
        },
        {
            "nombre": "Altura de salto",
            "label": "Altura de salto",
            "value": "50"
        },
        {
            "nombre": "Momentum de salto",
            "label": "Momentum de salto",
            "value": "51"
        },
        {
            "nombre": "Tiempo de vuelo",
            "label": "Tiempo de vuelo",
            "value": "52"
        },
        {
            "nombre": "Asimetría de fuerza de frenado promedio (Izquierda/derecha)",
            "label": "Asimetría de fuerza de frenado promedio (Izquierda/derecha)",
            "value": "53"
        },
        {
            "nombre": "Asimetría de fuerza de propulsión promedio (Izquierda/Derecha)",
            "label": "Asimetría de fuerza de propulsión promedio (Izquierda/Derecha)",
            "value": "54"
        },
        {
            "nombre": "Asimetría fuerza de frenado pico Izquierda/Derecha",
            "label": "Asimetría fuerza de frenado pico Izquierda/Derecha",
            "value": "55"
        },
        {
            "nombre": "Asimetría de fuerza de propulsión pico Izquierda/Derecha",
            "label": "Asimetría de fuerza de propulsión pico Izquierda/Derecha",
            "value": "56"
        },
        {
            "nombre": "Asimetría de fuerza de propulsión pico Derecha/Izquierda",
            "label": "Asimetría de fuerza de propulsión pico Derecha/Izquierda",
            "value": "57"
        },
        {
            "nombre": "Asimetría de potencia de propulsión pico Derecha/Izquierda",
            "label": "Asimetría de potencia de propulsión pico Derecha/Izquierda",
            "value": "58"
        },
        {
            "nombre": "Asimetría de potencia de propulsión pico Izquierda/Derecha",
            "label": "Asimetría de potencia de propulsión pico Izquierda/Derecha",
            "value": "59"
        },
        {
            "nombre": "Asimetría de impulso de propulsión pico Derecha/Izquierda",
            "label": "Asimetría de impulso de propulsión pico Derecha/Izquierda",
            "value": "60"
        },
        {
            "nombre": "Asimetría de impulso de propulsión pico Izquierda/Derecha",
            "label": "Asimetría de impulso de propulsión pico Izquierda/Derecha",
            "value": "61"
        }
    ]
    

    //const [startValue, setStartValue] = useState(0);


    const cargar = async () => {
        try {
            setCargando(true)
            id.split(",").forEach(async (element,index) => {
                const { data } = await API(`prueba/${element}`)
                
                
                let param = await reCalcularParametros(data?.doc.sistema_de_pesos_y_tiempos,index)
                Object.assign(data.doc, { "id": index,"parametros":param});
                doc[index]=data.doc
                
                
                setDoc(doc)
                
                //console.log(doc)
       
            })
            

            //const { data } = await API(`prueba/${id.split(",")[0]}`)
            //setDoc(data?.doc)
        } catch (err) {
            notification.error({ description: err?.response?.data?.mensaje || err.toString() })
            navigate('/pruebas')
        } finally {
            setCargando(false)
        }
    }


    const reCalcularParametros = async (subset1,index) => {
        
        //setreCalculando(true)
        setreCalculando(true)
       
        const { fases: fases_calculadas, parametros: parametros_calculados } = await runCMJ(subset1)
        setParametros(parametros_calculados)
        setFases(fases_calculadas)
        return parametros_calculados
        
          
    }


      const descargarCSV = () => {
        // Comprobar si existe "sistema_de_pesos_y_tiempos"
        if (!doc?.sistema_de_pesos_y_tiempos) {
          console.error("No hay datos para descargar");
          return;
        }
      
        // Preparar los datos para el CSV
        const datosCSV = [["Tiempo", "Peso Izquierdo", "Peso Derecho"]];
        for (const elemento of doc?.sistema_de_pesos_y_tiempos) {
          datosCSV.push([elemento.tiempo, elemento.peso.izq, elemento.peso.der]);
        }
      
        // Convertir los datos a formato CSV
        const csv = datosCSV.map(fila => fila.join(",")).join("\n");
      
        // Crear un blob con los datos CSV
        const blob = new Blob([csv], { type: "text/csv" });
      
        // Generar un nombre de archivo con fecha y hora
        // const nombreArchivo = `datos_${new Date().toISOString().slice(0, 10)}.csv`;
      
        // Crear un enlace para descargar el archivo
        // const url = URL.createObjectURL(blob);
        // const enlace = document.createElement("a");
        // enlace.href = url;
        // enlace.download = nombreArchivo;
        // enlace.click();
      
        // // Eliminar el enlace una vez descargado el archivo
        // enlace.parentNode.removeChild(enlace);
        return { data: blob }
      }



    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/pruebas">Pruebas realizadas</Link>
        },
        {
            title: <Link to="/cuadrante">Cuadrante</Link>
        },
        
    ]
    // See https://github.com/ecomfe/echarts-stat
    echarts.registerTransform(ecStat.transform.clustering);
   
    var CLUSTER_COUNT = 1;
    var DIENSIION_CLUSTER_INDEX = 2;
    var COLOR_ALL = [
    '#37A2DA',
    '#e06343',
    '#37a354',
    '#b55dba',
    '#b5bd48',
    '#8378EA',
    '#96BFFF'
    ];
    var pieces = [];
    for (var i = 0; i < CLUSTER_COUNT; i++) {
    pieces.push({
        value: i,
        label: 'cluster ' + i,
        color: COLOR_ALL[i]
    });
    }

    const opciones_grafica = {
        
        dataset: [
            {
              source: table
            },
            {
              transform: {
                type: 'ecStat:clustering',
                // print: true,
                config: {
                  
                  clusterCount: CLUSTER_COUNT,
                  outputType: 'single',
                  outputClusterIndexDimension: DIENSIION_CLUSTER_INDEX
                }
              }
            }
          ],
          tooltip: {
            position: 'top'
          },
        grid: {
          left: 120
        },
        xAxis: {
            type: 'value',
            name: 'Parametro1',
            nameLocation: 'middle',
            axisLabel: {
                formatter: '{value}',
                align: 'center',
                textStyle: { color: () => color_area },
            },
            nameGap: 50,
            data:table[1],
            min: limitesEjes[0], // Fija el eje X para que inicie en 0
            max: limitesEjes[1],
            
        },
        yAxis: {
            type: 'value',
            name: 'Parametro2',
            nameLocation: 'middle',
            nameGap: 50,
            axisLabel: {
                textStyle: { color: () => color_area },
            },
            min: limitesEjes[2], // Fija el eje X para que inicie en 0
            max: limitesEjes[3],
            data:table[0]
        },
        series: {
          type: 'scatter',
          encode: { tooltip: [0, 1] },
          symbolSize: 15,
          itemStyle: {
            borderColor: '#555'
          },
          data:table[0]
          
        }
      };

      const guardar = async (payload)=>{
        try{
            setCargando(true)
            console.log(payload)
            const cuadranteData = []
            doc.forEach((element, index) => {
                cuadranteData.push({
                    "id":index,
                    "atleta":element.atleta,
                    "param1":[parametrosT[0],element.param1],
                    "param2":[parametrosT[1],element.param2],
                    "cuadrante":element.cuadrante
                })
            });
            await API.post('cuadrante',{...payload,cuadranteData})
            notification.success({
                description:'Cuadrante creado correctamente, puedes visualizarlo en la sección Cuadrantes'
            })
            
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString()
            })
            
        }finally{
            setCargando(false)
        }
    }


    const updateData = async () => {
        try {
            setCargando(true);
    
            const newTable = [[]];
            const limites = [0, 0, 0, 0];
            const params = [];
    
            // Calcular los límites antes de actualizar el estado
            doc.forEach((val, index) => {
                params.push([val.param1, val.param2]);
                newTable[0].push([val.param1, val.param2]);
    
                const porcentajeParam1 = (5 * val.param1) / 100;
                const porcentajeParam2 = (5 * val.param2) / 100;
    
                if (val.param1 < limites[0] || limites[0] === 0) {
                    limites[0] = val.param1 - porcentajeParam1;
                }
                if (val.param1 > limites[1] || limites[1] === 0) {
                    limites[1] = val.param1 + porcentajeParam1;
                }
                if (val.param2 < limites[2] || limites[2] === 0) {
                    limites[2] = val.param2 - porcentajeParam2;
                }
                if (val.param2 > limites[3] || limites[3] === 0) {
                    limites[3] = val.param2 + porcentajeParam2;
                }
            });
    
            // Aquí, calculas los límites y cuadrantes antes de actualizar el estado
            let updatedLimites = [...limites];
            console.log(paramsAutomatic)
            if (paramsAutomatic[0] && paramsAutomatic[1]) {
                // Usas los límites completos si ambos parámetros son automáticos
                updatedLimites = limites;
            
            }else if(!paramsAutomatic[0] && !paramsAutomatic[1]){
                updatedLimites = [limitesEjes[0], limitesEjes[1], limitesEjes[2], limitesEjes[3]];
            } 
            else if (paramsAutomatic[0]) {
                // Si solo el primer parámetro es automático, mantienes el segundo parámetro
                updatedLimites = [limites[0], limites[1], limitesEjes[2], limitesEjes[3]];
            } else if (paramsAutomatic[1]) {
                // Si solo el segundo parámetro es automático, mantienes el primer parámetro
                updatedLimites = [limitesEjes[0], limitesEjes[1], limites[2], limites[3]];
            }
    
            // Calcula los centros de los límites
            const centers = [
                parseFloat(((updatedLimites[0] + updatedLimites[1]) / 2).toFixed(2)),
                parseFloat(((updatedLimites[2] + updatedLimites[3]) / 2).toFixed(2)),
            ];
    
            console.log(centers);
    
            // Actualiza el cuadrante de cada parámetro en base a su posición
            newTable[0].forEach((value, index) => {
                if (value[0] > centers[0] && value[1] > centers[1]) {
                    Object.assign(doc[index], { cuadrante: "I" });
                }
                if (value[0] < centers[0] && value[1] > centers[1]) {
                    Object.assign(doc[index], { cuadrante: "II" });
                }
                if (value[0] < centers[0] && value[1] < centers[1]) {
                    Object.assign(doc[index], { cuadrante: "III" });
                }
                if (value[0] > centers[0] && value[1] < centers[1]) {
                    Object.assign(doc[index], { cuadrante: "IV" });
                }
            });
    
            // Finalmente, actualizas el estado de limitesEjes después de los cálculos
            setLimitesEjes(updatedLimites);
            setTable(newTable);
    
        } catch (error) {
            console.log(error);
        } finally {
            setCargando(false);
        }
    };
    
      
      const handleChangep1 = async (value) => {
        let obje = optionsParametros[value].nombre
        setParametrosT([obje,parametrosT[1]])
 
        
        try {
            setCargando(true)
           
            for(let i=0 ;i<doc.length;i++){
                doc[i].parametros.forEach((val) => {
                    if(val.nombre == obje){
                        Object.assign(doc[i], { "param1": val.valor});
                        
                    }
                })

            }
            
        } catch (error) {
            console.log(error)
        }
        finally{
            setCargando(false)
            //console.log(doc)
        }
        
      };
      const handleChangep2 = async (value) => {
        let obje = optionsParametros[value].nombre
        
        setParametrosT([parametrosT[0],obje])
        
        try {
            setCargando(true)
            let params
            for(let i=0 ;i<doc.length;i++){
                doc[i].parametros.forEach((val) => {
                    if(val.nombre == obje){
                        Object.assign(doc[i], { "param2": val.valor});
                      
                    }
                })

            }
            
        } catch (error) {
            console.log(error)
        }
        finally{
            setCargando(false)
            //console.log(doc)
        }
        
          
      
      
      };
    const changeLimits = async (payload)=>{
        console.log(payload)
    }


    useEffect(() => {
        cargar()

    }, [])

    



    return <div >
        <div style={{ padding: 32 }}>
            <Breadcrumb items={miga} />
        </div>
        <Typography.Title style={{ textAlign: 'center' }}>{"Informe De Cuadrantes" || ''} </Typography.Title>
         <br></br>
         <Form>

            <Flex gap="middle" justify="space-evenly">
                
                <Form.Item name={'Parametro1'} label="Parametro1" >
                    <Select allowClear style={{width:450, margin:10}} options={optionsParametros} onChange={handleChangep1}/>
                </Form.Item>
                <Form.Item name={'parametro2'} label="Parametro2" >
                        
                    <Select allowClear style={{width:500}} options={optionsParametros}  onChange={handleChangep2}/>
            
                </Form.Item>
            </Flex> 


         </Form>
           
        <div style={{ padding: 32 }}>
            {//<Typography.Paragraph>{doc?.impresion || 'No se ingreso impresión profesional'}</Typography.Paragraph>
            }
            
            
           
            <div style={{ height: 600 }}>

                <ECharts ref={grafica} lazyUpdate={true} theme={'dark2'} option={opciones_grafica} style={{ width: '100%', height: '100%' }} />
            </div>
            <br></br>
            <Flex gap="middle" justify="space-around">
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}
                {<Button type="primary"  onClick={updateData}>Calcular Cuadrante</Button>}
             
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}

            </Flex>
            
            
            <br></br>
            <Flex gap="middle" justify="space-evenly">
            <Table rowKey={'id'} loading={cargando} dataSource={doc} pagination={false} style={{width:1500}} >
                <Table.Column title="Puesto" dataIndex={"id"} width="20%"/>
                <Table.Column title="Atleta" dataIndex={"nombre"}  width="20%"/>
                <Table.Column title={parametrosT[0]} dataIndex={"param1"}  width="20%"/>
                <Table.Column title={parametrosT[1]} dataIndex={"param2"} width="20%" />
                <Table.Column title="Cuadrante" dataIndex={"cuadrante"} width="30%"/>
            </Table>
            </Flex>
            <br></br>
            <Flex gap="middle" justify="space-evenly">
                <CrearGrupoModalCuadrante abrir={abrir} cerrar={setAbrir} save={guardar}/>
                <CrearGrupoModalCuadranteParams 
                limitesEjes={limitesEjes} changeLimit={setLimitesEjes} 
                abrir={abrirParams[0]} cerrar={setAbrirParams} 
                params={abrirParams[1]} nameParam={parametrosT[(abrirParams[1]-1)]} 
                automatic={paramsAutomatic} 
                setAutomatic={setParamsAutomatic} 
                 />
                  
                {<Button type="primary"  onClick={()=>{setAbrirParams([true,1])}} >Parametro1</Button>}
                {<Button type="primary"  onClick={()=>{setAbrirParams([true,2])}} >Parametro2</Button>}
                </Flex>
            <br></br>
            <br></br>
            <Flex gap="middle" justify="space-around">
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}
                {<Button type="primary"  onClick={()=>{setAbrir(true)}}>Guardar Reporte</Button>}
             
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}

            </Flex>



        </div>
    </div>
}
export default PruebaCuadrante