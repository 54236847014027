import { Button, Form, Input, Modal, Typography, notification,Flex } from "antd"
import { useState } from "react"
//import API from "../../../../../API"

const CrearGrupoModalCuadrante=({abrir,cerrar,save})=>{
    const [cargando,setCargando] = useState(false)

    const cerrarModal= async (payload)=>{
        await save(payload)
        cerrar(true)
        setCargando(false)
    }


    
    return <Modal open={abrir} closable={!cargando} footer={[]} onCancel={()=>cerrar(false)} title="Reporte de Cuadrantes">
        <Form disabled={cargando} layout="vertical" onFinish={cerrarModal}>
        <Form.Item label="Titulo" name="titulo" rules={[{required:true,message:'Ingrese un titulo para el reporte'}]}>
            <Input/>
        </Form.Item>
        <Form.Item label="Descripción" name="descripcion" rules={[{required:true,message:'Ingrese un titulo para el reporte'}]}>
            <Input/>
        </Form.Item>
            <Flex gap="middle" justify="space-evenly"><Button loading={cargando} type="primary" htmlType="submit">Guardar</Button></Flex>
        </Form>
    </Modal>
}

export default CrearGrupoModalCuadrante