import axios from "axios";

export const BASE_URL= process.env.REACT_APP_BASE_URL 

const API=axios.create({
    baseURL:BASE_URL,
    withCredentials:true
})


API.interceptors.request.use((config)=>{
    const token = localStorage.getItem('sesion')
  
    if(token && token!='' && token!='null'){
        config.headers['authorization']='Bearer '+token
    }
    return config
})

export default API
