import { useEffect, useRef, useState } from "react"
import { Transition,CSSTransition } from 'react-transition-group';
import Contexto from "./Ctx"
import Cargando from "../../Componentes/Cargando"
import { useNavigate } from "react-router-dom";
import API from "../../API";

const SesionProveedor=({children})=>{
    const navigate = useNavigate()
    const [cargando,setCargando] = useState(true)
    const [usuario,setUsuario] = useState(null)
    const nodeRef = useRef(null);
    const [filtro_prueba,setFiltroPrueba] = useState({})

    const esperar=(t)=>{
        return new Promise((r)=>setTimeout(r,t))
    }

    const validar=async ({redirigir=false})=>{
        if(!localStorage.getItem("sesion")){
            setUsuario(null)
            setCargando(false)
            return
        }
       
        try{
            setCargando(true)
            const {data} = await API(`usuario/sesion`)
            setUsuario(data.doc)
            if(redirigir){
                navigate("/")
            }
        }catch(err){
            setUsuario(null)
        }finally{
            setCargando(false)
        }
    }

    const login=({token,redirigir=false})=>{
        localStorage.setItem('sesion',token)
        validar({redirigir})
    }

    const logout=()=>{
        localStorage.removeItem('sesion')
        setUsuario(null)
    }

    const value = {
        usuario,login,logout,filtro_prueba,setFiltroPrueba
    }

    useEffect(()=>{
        validar({})
    },[])

    return <Contexto.Provider value={value}>
        <CSSTransition nodeRef={nodeRef} in={cargando} unmountOnExit  timeout={500} classNames='cargando-'>
        <div ref={nodeRef}><Cargando/></div>
        </CSSTransition>

        <CSSTransition nodeRef={nodeRef} in={!cargando} unmountOnExit  timeout={700} classNames='cargando-'>
        {children}
        </CSSTransition>
       
    </Contexto.Provider>
}

export default SesionProveedor