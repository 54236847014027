import useSesion from "../../Hooks/Sesion"

const ValidarRol=({roles=[],children,FeedBack})=>{
    const {usuario} = useSesion()

    if(!roles.length || roles.includes(usuario?.rol)){
        return children
    }
    return  FeedBack ? <FeedBack/> : ''

}

export default ValidarRol