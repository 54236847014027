import { Button, Form, Input, Modal, Flex, Checkbox } from "antd";
import { useState } from "react";

const CrearGrupoModalCuadranteParams = ({ abrir, cerrar, params, nameParam, 
    changeLimit, limitesEjes, automatic, setAutomatic }) => {

    const [cargando, setCargando] = useState(false);
    const [form] = Form.useForm();

    // Maneja el cambio en el checkbox
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        const newAutomatic = [...automatic];
        newAutomatic[params - 1] = isChecked;
        //console.log(newAutomatic);
        setAutomatic(newAutomatic);
    };

    const changelim = () => {
        const limtInfe = parseFloat(form.getFieldValue('limtInfe'));
        const limSup = parseFloat(form.getFieldValue('limSup'));

        if (params === 1) {
            changeLimit([limtInfe, limSup, limitesEjes[2], limitesEjes[3]]);
        } else {
            changeLimit([limitesEjes[0], limitesEjes[1], limtInfe, limSup]);
        }
        cerrar(false);
    };

    return (
        <Modal 
            open={abrir} 
            closable={!cargando} 
            footer={[]} 
            onCancel={() => cerrar(false)} 
            title={`Parámetro de eje ${params}: ${nameParam}`} 
        >
            <Form 
                form={form} 
                disabled={cargando} 
                layout="vertical"
            >
                <Flex gap="middle" align="center">
                    <Form.Item>
                        <Flex gap="middle" align="center">
                            <p>{automatic[params - 1] ? "Automático" : "Manual"}</p>
                            <Checkbox 
                                checked={automatic[params - 1]} 
                                onChange={handleCheckboxChange}
                            />
                        </Flex>
                    </Form.Item>
                    <Form.Item 
                        label="Límite Inferior" 
                        name="limtInfe" 
                        rules={[{ required: true, message: 'Ingrese el límite inferior' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="Límite Superior" 
                        name="limSup" 
                        rules={[{ required: true, message: 'Ingrese el límite superior' }]}
                    >
                        <Input />
                    </Form.Item>
                </Flex>
                <br />

                <Form.Item>
                    <Flex gap="middle" align="center" justify="center">
                        <Button onClick={changelim}>Seleccionar</Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CrearGrupoModalCuadranteParams;
