// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cargando-enter {
    opacity: 0;
  }
  .cargando-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .cargando-exit {
    opacity: 1;
  }
  .cargando-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }`, "",{"version":3,"sources":["webpack://./src/Componentes/Cargando/estilo.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;EACA;IACE,UAAU;IACV,yBAAyB;EAC3B;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,yBAAyB;EAC3B","sourcesContent":[".cargando-enter {\n    opacity: 0;\n  }\n  .cargando-enter-active {\n    opacity: 1;\n    transition: opacity 200ms;\n  }\n  .cargando-exit {\n    opacity: 1;\n  }\n  .cargando-exit-active {\n    opacity: 0;\n    transition: opacity 200ms;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
