import useSesion from "../../Hooks/Sesion"
import { Navigate } from "react-router-dom";

const ValidarSesion=({children})=>{
    const {usuario} = useSesion()

    if(!usuario){
        console.log("No hay usuario")
       return <Navigate replace to="/login"/>
    }

    return children
}

export default ValidarSesion