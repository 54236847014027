import { Button, DatePicker, Form, Modal, Input, notification, Table, Typography, Card } from "antd"
import { useForm } from "antd/es/form/Form"
import dayjs from "dayjs";
import { useEffect, useState } from "react"
import API from "../../API";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";

const SeleccionarMuestra = ({ onSeleccionar, tipe }) => {
    const [cargando, setCargando] = useState(false)
    const [abrir, setAbrir] = useState(false)
    const [docs, setDocs] = useState([])
    const [doc, setDoc] = useState(null)
    const [form] = useForm()

    const consultar = async ({ fecha, ...payload }) => {
        try {

            const params = {
                ...payload,
                fi: fecha[0].toDate(),
                ff: fecha[1].toDate(),
                tp: tipe
            }
            setCargando(true)
            const { data } = await API('muestra', { params })
            setDocs(data?.docs)
        } catch (err) {
            notification.error({ description: err?.response?.data?.mensaje || err.toString() })
        } finally {
            setCargando(false)
        }
    }

    const seleccionar = async (muestra) => {
        try {
            setCargando(true)
            const {data} = await API(`muestra/${muestra._id}`)
            setDoc(data?.doc)
            setAbrir(false)
            onSeleccionar(data?.doc)
        } catch (err) {

        } finally {
            setCargando(false)
        }
    }
    const abrirSelector = () => {
        setDoc(null)
        setAbrir(true)
        onSeleccionar(null)
    }

    useEffect(() => {
        form.setFieldsValue({ fecha: [dayjs().subtract(1, 'month'), dayjs()] })
    }, [])


    return <>
        <Modal closable={!cargando} open={abrir} onCancel={() => setAbrir(false)} title="Selcción de muestra" width={960} footer={[]}>
            <Form form={form} layout="inline" onFinish={consultar}>
                <Form.Item label="Fecha" name={'fecha'} rules={[{ required: true, message: 'Ingrese un rango' }]}>
                    <DatePicker.RangePicker />
                </Form.Item>
                <Form.Item label="Nombre" name={'nombre'}>
                    <Input />
                </Form.Item>
                <Button type="primary" htmlType="submit">Filtrar</Button>
            </Form>
            <br>
            </br>
            <Table dataSource={docs} pagination={false} scroll={{ y: 400 }}>
                <Table.Column title="Fecha" dataIndex={'createdAt'} render={(f) => dayjs(f).format("YYYY/MM/DD hh:mm a")} />
                <Table.Column title="Nombre" dataIndex={'nombre'} />
                <Table.Column title="Seleccionar" render={(muestra) => <Button onClick={() => seleccionar(muestra)} icon={<RightOutlined />} shape="circle" type="primary" />} />
            </Table>
        </Modal>
        {doc ? <Card actions={[<Button onClick={abrirSelector} icon={<CloseOutlined />}>Seleccionar otra muestra </Button>]}><Typography.Paragraph>Comparando con muestra {tipe} <Typography.Text mark>{doc?.nombre}</Typography.Text></Typography.Paragraph></Card> : <Button type="primary" onClick={() => setAbrir(true)}>Seleccionar Muestra</Button>}

    </>
}

export default SeleccionarMuestra