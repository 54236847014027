import { BankOutlined, ExperimentOutlined, PoweroffOutlined, TeamOutlined } from "@ant-design/icons"
import { Card, Col, Row, Typography } from "antd"
import { Link } from "react-router-dom"
import useSesion from "../../Hooks/Sesion"

const Inicio = () => {
    const { logout } = useSesion()
    return <div style={{ margin: 64 }}>
        <Typography.Title>SEFUM</Typography.Title>

       

            <Row justify={'center'} gutter={[24, 24]} align="stretch">
                <Col xs={24} sm={12} md={6}>
                    <Link to="/atletas">
                        <Card style={{ textAlign: 'center' }}>
                            <TeamOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Atletas</Typography.Title>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Link to="/pruebas">
                        <Card style={{ textAlign: 'center' }}>
                            <ExperimentOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Historico de pruebas</Typography.Title>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Link to="/instituciones">
                        <Card style={{ textAlign: 'center' }}>
                            <BankOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Instituciones</Typography.Title>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card style={{ textAlign: 'center' }} onClick={logout}>
                        <PoweroffOutlined style={{ fontSize: '7.5em' }} />
                        <Typography.Title level={4}>Cerrar</Typography.Title>
                    </Card>
                </Col>
            </Row>

            <Typography.Title level={2}>Reportes</Typography.Title>
        </div>
}

            export default Inicio