import { ACELERACION_GRAVEDAD, UMBRAL_DE_DESVIACION } from "../../../../Constantes";
import {
    trapz,
    calcularAreaBajoCurva,
    encontrarIndiceAproximado,
    encontrarIndiceFueraDeLimites,
    calcularDesplazamientoCentroDeMasa,
    calcularVelocidadCentroDeMasaCaida,
    encontrarIndiceAproximadoPeso,
    Sdt,
    Mean,
    derivar, obtenerTiempos, obtenerPesosDer, obtenerPesosIzq, obtenerPesos, obtenerMasas, RPD, ConcentricMeanPower, concentricMaxRFD, calcularVelocidadCentroDeMasa,
} from "../../../../Util"
export async function runCMJ(sistema_de_pesos_y_tiempos) {
    const FASES=[],aceleraciones=[],velocidades = [],tiempo_velocidades=[], desplazamientos = [],impulsos=[],impulsos_iz=[],impulsos_der=[];
    
    const tiempoPesaje = sistema_de_pesos_y_tiempos[0].tiempo + 0.5
    //console.log ("Tiempo inicial: ", tiempoPesaje)
  
    const muestra_de_masas = sistema_de_pesos_y_tiempos.filter(c => c.tiempo <= tiempoPesaje).map(c => c.masa.combinada) // filtrar los datos que se usan para calcular el pss
  
    const media_de_masa = Mean(muestra_de_masas)
    const desviacion_de_masa = Sdt(muestra_de_masas)
    const min_fase1 = media_de_masa - (desviacion_de_masa * UMBRAL_DE_DESVIACION)
    const max_fase1 = media_de_masa + (desviacion_de_masa * UMBRAL_DE_DESVIACION)

    //Calcular fase 1 Descanso
    const FASE_DESCANSO = {
        inicio: 0,
        fin: 0,
        color:'#CCD1D1',
        nombre:'Descanso'
    }
    for (let i = 0; i < sistema_de_pesos_y_tiempos.length; i++) {
        const masa_combinada = sistema_de_pesos_y_tiempos[i].masa.combinada
        if (masa_combinada >= min_fase1 && masa_combinada <= max_fase1) {
            FASE_DESCANSO.fin = i;
        } else {
            break
        }
    }
    const MASA_SUJETO = Mean(obtenerMasas(sistema_de_pesos_y_tiempos, FASE_DESCANSO))
    const PESO_SUJETO = MASA_SUJETO * ACELERACION_GRAVEDAD
   

    //Calcular fase 2 Descarga
    const FASE_DESCARGA = {
        inicio: FASE_DESCANSO.fin,
        fin: -1,
        area: 0,
        color:'#EBD494',
        nombre:'Descarga'
    }
    for (let i = FASE_DESCARGA.inicio; i < sistema_de_pesos_y_tiempos.length; i++) {
        const masa_combinada = sistema_de_pesos_y_tiempos[i].masa.combinada
        if (masa_combinada < MASA_SUJETO) {
            FASE_DESCARGA.fin = i
        }
        if (masa_combinada > MASA_SUJETO + desviacion_de_masa && FASE_DESCARGA.fin != -1) {
            break
        }
    }

    

    const tiempos_fase_descarga = obtenerTiempos(sistema_de_pesos_y_tiempos, FASE_DESCARGA)
    const pesos_fase_descarga = obtenerPesos(sistema_de_pesos_y_tiempos, FASE_DESCARGA)

    FASE_DESCARGA.area = calcularAreaBajoCurva(tiempos_fase_descarga, pesos_fase_descarga, PESO_SUJETO)
  

    //Calcular fase 3 Frenado
    const FASE_FRENADO = {
        inicio: 0,
        fin: 0,
        color:'#D7EBBA',
        nombre:'Frenado'
    }
    FASE_FRENADO.inicio = FASE_DESCARGA.fin
    // console.log({PESO_SUJETO})
   const tiempos_desde_fase2 = sistema_de_pesos_y_tiempos.filter((f, i) => i >= FASE_DESCARGA.fin).map(f => f.tiempo)
    const pesos_desde_fase2 = sistema_de_pesos_y_tiempos.filter((f, i) => i >= FASE_DESCARGA.fin).map(f => f.peso.combinada)
    // console.log({FASE_DESCARGA})
    FASE_FRENADO.fin = FASE_FRENADO.inicio+encontrarIndiceAproximadoPeso(tiempos_desde_fase2, pesos_desde_fase2, PESO_SUJETO, FASE_DESCARGA.area)
    
    if(FASE_FRENADO.fin==FASE_FRENADO.inicio){
        FASE_FRENADO.fin+=1
    }
    // console.log({
    //     FASE_FRENADO
    // })
    //Fase 3 Punto de transferencia
    const punto_de_transferencia = FASE_FRENADO.fin

    //Calcular fase 4 Propulsion
    const FASE_PROPULSION = {
        inicio: 0,
        fin: 0,
        color:'#9AD2CB',
        nombre:'Propulsion'
    }
    FASE_PROPULSION.inicio = FASE_FRENADO.fin

    for (let i = FASE_FRENADO.fin; i < sistema_de_pesos_y_tiempos.length; i++) {
        const peso = sistema_de_pesos_y_tiempos[i].peso.combinada
        if (peso <= 60) {
            FASE_PROPULSION.fin = i
            break
        }
    }
    //Calcular fase 5 vuelo
    const FASE_VUELO = {
        inicio: FASE_PROPULSION.fin,
        fin: 0,
        color:'#3F8D84',
        nombre:'Vuelo'
    }
    for (let i = FASE_VUELO.inicio; i < sistema_de_pesos_y_tiempos.length; i++) {
        const peso = sistema_de_pesos_y_tiempos[i].peso.combinada
        if (peso >= 60) {
            break
        }
        FASE_VUELO.fin = i
    }
    //Calcular fase 6 aterrizaje
    const FASE_ATERRIZAJE = {
        inicio: FASE_VUELO.fin,
        fin: 0,
        color:'#668E29',
        nombre:'Aterrizaje'
    }
    const umbral = 0.1
    for(let i = sistema_de_pesos_y_tiempos.length-1;i>FASE_ATERRIZAJE.inicio;i--){
        const masa = sistema_de_pesos_y_tiempos[i].masa.combinada
        if((masa-MASA_SUJETO)-desviacion_de_masa>umbral){
            FASE_ATERRIZAJE.fin = i
            break
        }
    }
    //Fase excentrica
    const FASE_EXCENTRICA={
        tiempo:{
            inicio:sistema_de_pesos_y_tiempos[FASE_DESCARGA.inicio].tiempo,
            fin:sistema_de_pesos_y_tiempos[FASE_FRENADO.fin].tiempo,
            duracion:sistema_de_pesos_y_tiempos[FASE_FRENADO.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_DESCARGA.inicio].tiempo
        }
    }
    //Calcular aceleraciones

    for(let i=0;i<sistema_de_pesos_y_tiempos.length;i++){
        const aceleracion = (sistema_de_pesos_y_tiempos[i].peso.combinada-PESO_SUJETO)/MASA_SUJETO
        aceleraciones.push(aceleracion)
    }
  
    //Calcular velocidades
    velocidades.push(0)
    tiempo_velocidades.push(0)

    for(let i=1;i<sistema_de_pesos_y_tiempos.length-1;i++){
        const tiempo_siguiente = sistema_de_pesos_y_tiempos[i+1].tiempo
        const tiempo_actual = sistema_de_pesos_y_tiempos[i].tiempo
        const peso_siguiente = sistema_de_pesos_y_tiempos[i+1].peso.combinada
        const peso_actual = sistema_de_pesos_y_tiempos[i].peso.combinada

        const t_promedio=tiempo_siguiente+tiempo_actual
        const delta_t = tiempo_siguiente-tiempo_actual
        const delta_p = peso_siguiente+peso_actual

        const velocidad =((((delta_p)/2)*delta_t)- (PESO_SUJETO*delta_t))/MASA_SUJETO +velocidades[i-1];
 
        velocidades.push(velocidad)
        tiempo_velocidades.push(t_promedio)
        
    }

    //Calcular impulsos
    impulsos.push(0)
    impulsos_iz.push(0)
    impulsos_der.push(0)

    for(let i=1;i<sistema_de_pesos_y_tiempos.length-1;i++){
        const tiempo_siguiente = sistema_de_pesos_y_tiempos[i+1].tiempo
        const tiempo_actual = sistema_de_pesos_y_tiempos[i].tiempo
        const peso_siguiente = sistema_de_pesos_y_tiempos[i+1].peso.combinada
        const peso_actual = sistema_de_pesos_y_tiempos[i].peso.combinada
        //-------Izquierdo Derecho ----------------//
        const peso_siguiente_iz=sistema_de_pesos_y_tiempos[i+1].peso.izq
        const peso_siguiente_der=sistema_de_pesos_y_tiempos[i+1].peso.der
        const peso_actual_iz=sistema_de_pesos_y_tiempos[i].peso.izq
        const peso_actual_der=sistema_de_pesos_y_tiempos[i].peso.der

        const delta_t = tiempo_siguiente-tiempo_actual
        const delta_p = peso_siguiente+peso_actual
        const delta_p_iz=peso_siguiente_iz+peso_actual_iz
        const delta_p_der=peso_siguiente_der+peso_actual_der

        const impulso =((((delta_p)/2)*delta_t)- (PESO_SUJETO*delta_t)) +impulsos[i-1];
        const impulso_iz =((((delta_p_iz)/2)*delta_t)-((PESO_SUJETO/2)*delta_t))+impulsos_iz[i-1];
        const impulso_der =((((delta_p_der)/2)*delta_t)-((PESO_SUJETO/2)*delta_t))+impulsos_der[i-1];
    
        impulsos.push(impulso)
        impulsos_iz.push(impulso_iz)
        impulsos_der.push(impulso_der)
        
    }

    
    //Calcular desplazamientos
    desplazamientos.push(0)

    for(let i=1;i<sistema_de_pesos_y_tiempos.length-1;i++){
        const tiempo_siguiente =sistema_de_pesos_y_tiempos[i+1].tiempo
        const tiempo_actual =sistema_de_pesos_y_tiempos[i].tiempo
        const velocidad_siguiente = velocidades[i+1]
        const velocidad_actual = velocidades[i]

        const delta_t = tiempo_siguiente-tiempo_actual
        const delta_v = velocidad_siguiente+velocidad_actual

        const desplazamiento =(((delta_v)/2)*delta_t)+desplazamientos[i-1]
        desplazamientos.push(desplazamiento)
    }

    //Calcular parametros
    const TRABAJO = sistema_de_pesos_y_tiempos.map((s,i)=>s.peso.combinada*desplazamientos[i])
    //const POTENCIA=sistema_de_pesos_y_tiempos.map((s,i)=>s.peso.combinada*velocidades[i])
    const POTENCIA=[]
    for(let i=1;i<TRABAJO.length-1;i++){
        const tiempo_siguiente = sistema_de_pesos_y_tiempos[i+1].tiempo
        const tiempo_actual = sistema_de_pesos_y_tiempos[i].tiempo
        const TRABAJO_siguiente = TRABAJO[i+1]
        const TRABAJO_actual = TRABAJO[i]

        const delta_t = tiempo_siguiente-tiempo_actual
        const delta_tr = TRABAJO_siguiente-TRABAJO_actual

        const POT =(delta_tr/delta_t)
        POTENCIA.push(POT)
    }  

    //Calculo del RFD Rata de desarrollo de fuerza.
    const derivadas_pesos=derivar(sistema_de_pesos_y_tiempos.map(s=>s.tiempo),sistema_de_pesos_y_tiempos.map(s=>s.peso.combinada))
    const derivadas_pesos_izq=derivar(sistema_de_pesos_y_tiempos.map(s=>s.tiempo),sistema_de_pesos_y_tiempos.map(s=>s.peso.izq))
    const derivadas_pesos_der=derivar(sistema_de_pesos_y_tiempos.map(s=>s.tiempo),sistema_de_pesos_y_tiempos.map(s=>s.peso.der))
  

    const DURACION_DE_FASES = sistema_de_pesos_y_tiempos[FASE_ATERRIZAJE.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_DESCARGA.inicio].tiempo

    FASE_DESCANSO.tiempo={
        inicio:sistema_de_pesos_y_tiempos[FASE_DESCANSO.inicio].tiempo,
        fin:sistema_de_pesos_y_tiempos[FASE_DESCANSO.fin].tiempo,
        duracion:sistema_de_pesos_y_tiempos[FASE_DESCANSO.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_DESCANSO.inicio].tiempo,
    }

    FASE_DESCARGA.tiempo={
        inicio:sistema_de_pesos_y_tiempos[FASE_DESCARGA.inicio].tiempo,
        fin:sistema_de_pesos_y_tiempos[FASE_DESCARGA.fin].tiempo,
        duracion:sistema_de_pesos_y_tiempos[FASE_DESCARGA.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_DESCARGA.inicio].tiempo,
    }
    FASE_FRENADO.tiempo={
        inicio:sistema_de_pesos_y_tiempos[FASE_FRENADO.inicio].tiempo,
        fin:sistema_de_pesos_y_tiempos[FASE_FRENADO.fin].tiempo,
        duracion:sistema_de_pesos_y_tiempos[FASE_FRENADO.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_FRENADO.inicio].tiempo,
    }
    FASE_PROPULSION.tiempo={
        inicio:sistema_de_pesos_y_tiempos[FASE_PROPULSION.inicio].tiempo,
        fin:sistema_de_pesos_y_tiempos[FASE_PROPULSION.fin].tiempo,
        duracion:sistema_de_pesos_y_tiempos[FASE_PROPULSION.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_PROPULSION.inicio].tiempo,
    }
    FASE_VUELO.tiempo={
        inicio:sistema_de_pesos_y_tiempos[FASE_VUELO.inicio].tiempo,
        fin:sistema_de_pesos_y_tiempos[FASE_VUELO.fin].tiempo,
        duracion:sistema_de_pesos_y_tiempos[FASE_VUELO.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_VUELO.inicio].tiempo,
    }
    FASE_ATERRIZAJE.tiempo={
        inicio:sistema_de_pesos_y_tiempos[FASE_ATERRIZAJE.inicio].tiempo,
        fin:sistema_de_pesos_y_tiempos[FASE_ATERRIZAJE.fin].tiempo,
        duracion:sistema_de_pesos_y_tiempos[FASE_ATERRIZAJE.fin].tiempo-sistema_de_pesos_y_tiempos[FASE_ATERRIZAJE.inicio].tiempo,
    }

    FASE_DESCARGA.porcentaje = (FASE_DESCARGA.tiempo.duracion/DURACION_DE_FASES)*100

    const tiempo_en_despegar = FASE_DESCARGA.tiempo.duracion+FASE_FRENADO.tiempo.duracion+FASE_PROPULSION.tiempo.duracion
    const RSI = (FASE_VUELO.tiempo.duracion/tiempo_en_despegar)*100
    const fuerza_promedio_frenado = Mean(obtenerPesos(sistema_de_pesos_y_tiempos,FASE_FRENADO))
    const fuerza_promedio_frenado_izq = Mean(obtenerPesosIzq(sistema_de_pesos_y_tiempos,FASE_FRENADO))
    const fuerza_promedio_frenado_der = Mean(obtenerPesosDer(sistema_de_pesos_y_tiempos,FASE_FRENADO))
    const potencia_promedio_frenado = Mean(POTENCIA.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const velocidad_promedio_frenado = Mean(velocidades.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const fuerza_promedio_frenado_relativa = (fuerza_promedio_frenado/PESO_SUJETO)*100
    const potencia_promedio_frenado_relativa = (potencia_promedio_frenado/MASA_SUJETO)
    const impulso_de_frenado = impulsos[FASE_FRENADO.fin]-impulsos[FASE_FRENADO.inicio]
    const impulso_de_propulsion = impulsos[FASE_PROPULSION.fin]-impulsos[FASE_PROPULSION.inicio]
    const porcentaje_frenado_tiempo = (FASE_FRENADO.tiempo.duracion/DURACION_DE_FASES)*100
    const RFD=Mean(derivadas_pesos.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const fuerza_pico_de_frenado=Math.max(...sistema_de_pesos_y_tiempos.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin).map(f=>f.peso.combinada))
    const potencia_pico_de_frenado=Math.min(...POTENCIA.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const velocidad_pico_de_frenado=Math.min(...velocidades.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const fuerza_pico_relativa_de_frenado = (fuerza_pico_de_frenado/PESO_SUJETO)*100
    const potencia_pico_relativa_de_frenado = (potencia_pico_de_frenado/MASA_SUJETO)*100
    const tiempos_frenado=obtenerTiempos(sistema_de_pesos_y_tiempos,FASE_FRENADO)
    const delta_tiempo_frenado=tiempos_frenado[tiempos_frenado.length - 1]-tiempos_frenado[0]
    const impulso_neto_promedio_de_frenado=impulso_de_frenado - fuerza_promedio_frenado*delta_tiempo_frenado
    const impulso_neto_pico_de_frenado=impulso_de_frenado - fuerza_pico_de_frenado*delta_tiempo_frenado
    const impulso_pico_relativa_de_frenado = (impulso_de_frenado/MASA_SUJETO)*100
    const promedio_rfd_frenado_izq=Mean(derivadas_pesos_izq.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const promedio_rfd_frenado_der=Mean(derivadas_pesos_der.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))

    //console.log({fuerza_pico_de_frenado})
    const indice_fuerza_pico = sistema_de_pesos_y_tiempos.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin).map(f=>f.peso.combinada).findIndex(v=>v==fuerza_pico_de_frenado)
    //console.log({indice_fuerza_pico})
    const fuerza_de_frenado_pico_der=sistema_de_pesos_y_tiempos.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin)[indice_fuerza_pico].peso.der
    const fuerza_de_frenado_pico_izq=sistema_de_pesos_y_tiempos.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin)[indice_fuerza_pico].peso.izq
    const impulso_positivo = [...impulsos.slice(FASE_FRENADO.inicio,FASE_PROPULSION.fin)].reduce((a,b)=>a+b,0)
    const profundidad_contramovimiento=Math.min(...desplazamientos.filter(d=>!isNaN(d)))//Valor minimo de Desplazamiento..
    const fuerza_a_minimo_desplazamiento = Math.min(...sistema_de_pesos_y_tiempos.slice(0,FASE_DESCARGA.fin).map(s=>s.peso.combinada))
    const relacion_de_impulsos = impulso_de_frenado/impulso_de_propulsion
    const fuerza_relativa_a_minimo_desplazamiento=fuerza_a_minimo_desplazamiento/PESO_SUJETO
    const rigidez=fuerza_a_minimo_desplazamiento/profundidad_contramovimiento //Stiffness
    const fuerza_promedio_propulsion = Mean(obtenerPesos(sistema_de_pesos_y_tiempos,FASE_PROPULSION))
    const fuerza_promedio_propulsion_izq = Mean(obtenerPesosIzq(sistema_de_pesos_y_tiempos,FASE_PROPULSION))
    const fuerza_promedio_propulsion_der = Mean(obtenerPesosDer(sistema_de_pesos_y_tiempos,FASE_PROPULSION))
    const potencia_promedio_propulsion = Mean(POTENCIA.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin))
    const velocidad_promedio_propulsion = Mean(velocidades.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin))
    const fuerza_promedio_propulsion_relativa = (fuerza_promedio_propulsion/PESO_SUJETO)*100
    const potencia_promedio_propulsion_relativa = (potencia_promedio_propulsion/MASA_SUJETO)
    const fuerza_pico_de_propulsion=Math.max(...sistema_de_pesos_y_tiempos.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin).map(f=>f.peso.combinada))
    const potencia_pico_de_propulsion=Math.max(...POTENCIA.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin))
    const fuerza_pico_relativa_de_propulsion = (fuerza_pico_de_propulsion/PESO_SUJETO)*100
    const potencia_pico_relativa_de_propulsion = (potencia_pico_de_propulsion/MASA_SUJETO)*100
    const velocidad_pico=Math.max(...velocidades.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin))
    //const velocidad_pico=velocidades[FASE_PROPULSION.fin]
    const fase_propulsion_tiempo_porcentaje=FASE_PROPULSION.tiempo.duracion/DURACION_DE_FASES
    const impulso_relativo_propulsivo=impulso_de_propulsion/MASA_SUJETO
    const indice_fuerza_pico_propulsion = sistema_de_pesos_y_tiempos.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin).map(f=>f.peso.combinada).findIndex(v=>v==fuerza_pico_de_propulsion)
    // console.log({
    //     FASE_PROPULSION,
    //     d:sistema_de_pesos_y_tiempos.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin)
    // })

    const fuerza_de_propulsion_pico_der=sistema_de_pesos_y_tiempos.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin)[indice_fuerza_pico_propulsion].peso.der
    const fuerza_de_propulsion_pico_izq=sistema_de_pesos_y_tiempos.slice(FASE_PROPULSION.inicio,FASE_PROPULSION.fin)[indice_fuerza_pico_propulsion].peso.izq
    //---Desempeño de la fase de vuelo---------------
    const Tiempo_vuelo=FASE_VUELO.tiempo.duracion
    //const Velocidad_despegue=ACELERACION_GRAVEDAD*Tiempo_vuelo
    const Velocidad_despegue=velocidad_pico
    //const Altura_de_salto=velocidad_pico*(Tiempo_vuelo/2)-ACELERACION_GRAVEDAD*(Math.pow(Tiempo_vuelo/2,2))/2
    const Altura_de_salto=Velocidad_despegue*(Tiempo_vuelo/2)-ACELERACION_GRAVEDAD*(Math.pow(Tiempo_vuelo/2,2))/2
    const Momentum_de_salto=MASA_SUJETO*Velocidad_despegue
    //-- Asimetrias en la fase de vuelo-------------
    const Asimetria_fuerza_frenado_Promedio_Iz_Der=(fuerza_promedio_frenado_izq/fuerza_promedio_frenado_der )*100
    const Asimetria_fuerza_frenado_Promedio_Der_Iz=(fuerza_promedio_frenado_der/fuerza_promedio_frenado_izq )*100
    const RFD_der=Mean(derivadas_pesos_der.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const RFD_izq=Mean(derivadas_pesos_izq.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin))
    const Asimetria_frenado_promedio_RFD_Iz_Der=(RFD_izq/RFD_der)*100
    const Asimetria_frenado_promedio_RFD_Der_Iz=(RFD_der/RFD_izq)*100
    const Asimetria_propulsion_promedio_Der_Iz=(fuerza_promedio_propulsion_der/fuerza_promedio_propulsion_izq)*100
    const Asimetria_propulsion_promedio_Iz_Der=(fuerza_promedio_propulsion_izq/fuerza_promedio_propulsion_der)*100
    const Asimetria_impulso_frenado_index_Der_Iz= impulsos_der.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin).map((elemento, index) => (100*elemento)/impulsos_iz.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin)[index]);
    const Asimetria_impulso_frenado_index_Iz_Der=impulsos_iz.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin).map((elemento, index) => (100*elemento)/impulsos_der.slice(FASE_FRENADO.inicio,FASE_FRENADO.fin)[index]);
    const Asimetria_fuerza_frenado_pico_Der_Iz=(fuerza_de_frenado_pico_der/fuerza_de_frenado_pico_izq)*100 
    const Asimetria_fuerza_frenado_pico_Iz_Der=(fuerza_de_frenado_pico_izq/fuerza_de_frenado_pico_der)*100 
    const Asimetria_fuerza_propulsion_pico_Der_Iz=(fuerza_de_propulsion_pico_der/fuerza_de_propulsion_pico_izq)*100 
    const Asimetria_fuerza_propulsion_pico_Iz_Der=(fuerza_de_propulsion_pico_izq/fuerza_de_propulsion_pico_der)*100 
    //---Desempeño de la fase de aterrizaje----------
    const fuerza_aterrizaje_promedio=Mean(obtenerPesos(sistema_de_pesos_y_tiempos, FASE_ATERRIZAJE))
    const fuerza_aterrizaje_pico=Math.max(...obtenerPesos(sistema_de_pesos_y_tiempos, FASE_ATERRIZAJE))
    const fuerza_aterizaje_pico_relativa=((Math.max(...obtenerPesos(sistema_de_pesos_y_tiempos, FASE_ATERRIZAJE)))/PESO_SUJETO)*100
    const Rigidez_de_aterrizaje=Math.min(... desplazamientos.slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin))
    const indice_estabilizacion=encontrarIndiceFueraDeLimites(sistema_de_pesos_y_tiempos.map(s=>s.peso.combinada),PESO_SUJETO*(1-0.05),PESO_SUJETO*(1+0.05))
    const tiempo_estabilizacion=sistema_de_pesos_y_tiempos.map(t=> t.tiempo)[indice_estabilizacion]-sistema_de_pesos_y_tiempos.map(t=> t.tiempo)[FASE_ATERRIZAJE.inicio]
    //---Resultados izquierda derecha-----------------
    const fuerza_promedio_aterrizaje_izq=Mean(sistema_de_pesos_y_tiempos.map(fz=>fz.peso.izq).slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin))
    const fuerza_promedio_aterrizaje_der=Mean(sistema_de_pesos_y_tiempos.map(fz=>fz.peso.der).slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin))
    const fuerza_pico_aterrizaje_izq=Math.max(...sistema_de_pesos_y_tiempos.map(fz=>fz.peso.izq).slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin))
    const fuerza_pico_aterrizaje_der=Math.max(...sistema_de_pesos_y_tiempos.map(fz=>fz.peso.der).slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin))
    //Asimetría de la fase de aterrrizaje------------
    const Asimetria_fuerza_promedio_aterrizaje_der_iz=100*(fuerza_promedio_aterrizaje_izq/fuerza_promedio_aterrizaje_der)
    const Asimetria_fuerza_promedio_aterrizaje_iz_der=100*(fuerza_promedio_aterrizaje_der/fuerza_promedio_aterrizaje_izq)
    const Asimetria_fuerza_pico_aterrizaje_der_iz=100*(fuerza_pico_aterrizaje_der/fuerza_pico_aterrizaje_izq)
    const Asimetria_fuerza_pico_aterrizaje_iz_der=100*(fuerza_pico_aterrizaje_izq/fuerza_pico_aterrizaje_der)
    const Asimetria_impulso_aterrizaje_index_der_iz= impulsos_der.slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin).map((elemento, index) => (100*elemento)/impulsos_iz.slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin)[index]);
    const Asimetria_impulso_aterrizaje_index_iz_der=impulsos_iz.slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin).map((elemento, index) => (100*elemento)/impulsos_der.slice(FASE_ATERRIZAJE.inicio,FASE_ATERRIZAJE.fin)[index]);
    
    return {
      fases:[FASE_DESCANSO,FASE_DESCARGA,FASE_FRENADO,FASE_PROPULSION,FASE_VUELO,FASE_ATERRIZAJE],
      parametros:[
        {
            nombre:'Peso de Sujeto',
            valor:PESO_SUJETO,
            
        },
        {
            nombre:'Masa de Sujeto',
            valor:MASA_SUJETO,
            
        },
        {
            nombre:'Porcentaje fase descarga',
            valor:FASE_DESCARGA.porcentaje,
            u:'%'
        },
        {
            nombre:'RSI',
            valor:RSI,
            u:'%'
        },
        {
            nombre:'Tiempo en despegar',
            valor:tiempo_en_despegar,
            u:'s'
        },
        {
            nombre:'Fuerza promedio de frenado',
            valor:fuerza_promedio_frenado
        },
        {
            nombre:'Fuerza promedio de frenado pierna izq.',
            valor:fuerza_promedio_frenado_izq
        },
        {
            nombre:'Fuerza promedio de frenado pierna der.',
            valor:fuerza_promedio_frenado_der
        },
        {
            nombre:'Fuerza pico de frenado pierna der.',
            valor:fuerza_de_frenado_pico_der
        },
        {
            nombre:'Fuerza pico de frenado pierna izq.',
            valor:fuerza_de_frenado_pico_izq
        },
        {
            nombre:'Potencia promedio de frenado',
            valor:potencia_promedio_frenado
        },
        {
            nombre:'Velocidad promedio de frenado',
            valor:velocidad_promedio_frenado
        },
        {
            nombre:'Fuerza promedio de frenado relativa',
            valor:fuerza_promedio_frenado_relativa
        },
        {
            nombre:'Potencia promedio de frenado relativa',
            valor:potencia_promedio_frenado_relativa
        },
        {
            nombre:'Impulso de frenado',
            valor:impulso_de_frenado
        },
        {
            nombre:'Duración frenado',
            valor:FASE_FRENADO.tiempo.duracion,
            u:'s'
        },
        {
            nombre:'% duración frenado',
            valor:porcentaje_frenado_tiempo,
            u:'%'
        },
        {
            nombre:'RFD',
            valor:RFD,
            u:'N/s'
        },
        {
            nombre:'Fuerza pico de frenado',
            valor:fuerza_pico_de_frenado
        },
        {
            nombre:'Potencia pico de frenado',
            valor:potencia_pico_de_frenado
        },
        {
            nombre:'Velocidad pico de frenado',
            valor:velocidad_pico_de_frenado
        },
        {
            nombre:'Fuerza pico relativa de frenado',
            valor:fuerza_pico_relativa_de_frenado
        },
        {
            nombre:'Potencia pico relativa de frenado',
            valor:potencia_pico_relativa_de_frenado
        },
        {
            nombre:'Impulso pico relativa de frenado',
            valor:impulso_pico_relativa_de_frenado
        },
        {
            nombre:'Promedio RDF de frenado pierna izq.',
            valor:promedio_rfd_frenado_izq
        },
        {
            nombre:'Promedio RDF de frenado pierna der.',
            valor:promedio_rfd_frenado_der
        },
        {
            nombre:'Impulso positivo',
            valor:impulso_positivo
        },
        {
            nombre:'Profundidad de contramovimiento',
            valor:profundidad_contramovimiento
        },
        {
            nombre:'Fuerza a minimo desplazamiento',
            valor:fuerza_a_minimo_desplazamiento
        },
        {
            nombre:'Relación de impulsos',
            valor:relacion_de_impulsos
        },
        {
            nombre:'Fuerza relativa a minimo desplazamiento',
            valor:fuerza_relativa_a_minimo_desplazamiento
        },
        {
            nombre:'Rigidez de contramivimiento',
            valor:rigidez
        },
        {
            nombre:'Fuerza promedio de propulsión',
            valor:fuerza_promedio_propulsion
        },
        {
           nombre:'Fuerza promedio de propulsión izq',
           valor: fuerza_promedio_propulsion_izq
        },
        {
           nombre:'Fuerza promedio de propulsión der',
           valor: fuerza_promedio_propulsion_der
        },
        {
            nombre:'Potencia promedio de propulsión',
            valor:potencia_promedio_propulsion
        },
        {
            nombre:'Velocidad promedio de propulsión',
            valor:velocidad_promedio_propulsion
        },
        {
            nombre:'Fuerza promedio de propulsion relativa',
            valor:fuerza_promedio_frenado_relativa
        },
        {
            nombre:'Potencia promedio de propulsión relativa',
            valor:potencia_promedio_propulsion_relativa
        },
        {
            nombre:'Fuerza pico de propulsión',
            valor:fuerza_pico_de_propulsion
        },
        {
            nombre:'Potencia pico de propulsion',
            valor:potencia_pico_de_propulsion
        },
        {
            nombre:'Fuerza pico relativa de propulsion',
            valor:fuerza_pico_relativa_de_propulsion
        },
        {
            nombre:'Potencia pico relativa de propulsion',
            valor:potencia_pico_relativa_de_propulsion
        },
        // {
        //     nombre:'Velocidad pico',
        //     valor:velocidad_pico
        // },
        {
            nombre:'Impulso de propulsión',
            valor:impulso_de_propulsion
        },
        {
            nombre:'Fase propulsion duracion',
            valor:FASE_PROPULSION.tiempo.duracion,
            u:'s'
        },
        {
            nombre:'Fase propulsion duracion',
            valor:fase_propulsion_tiempo_porcentaje,
            u:'%'
        },
        {
            nombre:'Impulso relativo de propulsión',
            valor:impulso_relativo_propulsivo,
            u:"N*s/Kg"
        },
        {
            nombre:'Fuerza promedio de propulsión izq',
            valor:fuerza_promedio_propulsion_izq
        },
        {
            nombre:'Fuerza promedio de propulsión der',
            valor:fuerza_promedio_propulsion_der
        },
        {
            nombre:'Fuerza propulsiva der en pico de fuerza combinado',
            valor:fuerza_de_propulsion_pico_der
        },
        {
            nombre:'Fuerza propulsiva izq en pico de fuerza combinado',
            valor:fuerza_de_propulsion_pico_izq
        },
        {
            nombre:'Velocidad de despegue',
            valor: Velocidad_despegue
        },
        {
            nombre:'Altura de salto',
            valor:Altura_de_salto
        },
        {
            nombre:'Momentum de salto',
            valor:Momentum_de_salto,
            u:'Kg*m/s'
        },
        {
            nombre:'Tiempo de vuelo',
            valor:Tiempo_vuelo,
            u:'s'
        },
        {
            nombre:'Asimetría de fuerza de frenado promedio (Izquierda/derecha)',
            valor:Asimetria_fuerza_frenado_Promedio_Iz_Der,
            u:'%'
        },
        {
            nombre:'Asimetría de fuerza de frenado promedio (derecha/Izquierda)',
            valor:Asimetria_fuerza_frenado_Promedio_Der_Iz,
            u:'%'
        },
        {
            nombre:'Asimetría de frenado promedio RFD Izquierda/Derecha',
            valor:Asimetria_frenado_promedio_RFD_Iz_Der
        },
        {
            nombre:'Asimetría de frenado promedio RFD Derecha/Izquierda',
            valor:Asimetria_frenado_promedio_RFD_Der_Iz
        },
        {
            nombre:'Asimetría de fuerza de propulsoón promedio Derecha/Izquierda',
            valor:Asimetria_propulsion_promedio_Der_Iz
        },
        {
            nombre:'Asimetría de fuerza de propulsoón promedio Izquierda/Derecha',
            valor:Asimetria_propulsion_promedio_Iz_Der
        },
        {
            nombre:'Asimetría indice de impulso de frenado Derecha/Izquierda',
            valor:Mean(Asimetria_impulso_frenado_index_Der_Iz)
        },
        {
            nombre:'Asimetría indice de impulso de frenado Izquierda/Derecha',
            valor:Mean(Asimetria_impulso_frenado_index_Iz_Der)
        },
        {
            nombre:'Asimetría fuerza de frenado pico Derecha/Izquierda',
            valor:Asimetria_fuerza_frenado_pico_Der_Iz
        },
        {
            nombre:'Asimetría fuerza de frenado pico Izquierda/Derecha',
            valor:Asimetria_fuerza_frenado_pico_Iz_Der
        },
        {
            nombre:'Asimetría fuerza de propulsión pico Derecha/Izquierda',
            valor:Asimetria_fuerza_propulsion_pico_Der_Iz,
            u:'%'
        },
        {
            nombre:'Asimetría fuerza de propulsión pico Izquierda/Derecha',
            valor:Asimetria_fuerza_propulsion_pico_Iz_Der,
            u:'%'
        },
        {
            nombre:'Fuerza de aterrizaje promedio',
            valor:fuerza_aterrizaje_promedio,
            u:'N'
        },
        {
            nombre:'Fuerza de aterrizaje pico',
            valor:fuerza_aterrizaje_pico,
            u:'N'
        },
        {
            nombre:'Fuerza de aterrizaje pico relativa',
            valor:fuerza_aterizaje_pico_relativa,
            u:'%'
        },
        {
            nombre:'Rigidez de aterrizaje',
            valor:Rigidez_de_aterrizaje,
            u:'N/n'
        },
        {
            nombre:'Tiempo de estabilización',
            valor:tiempo_estabilizacion,
            u:'s'
        },
        {
            nombre:'Fuerza promedio de aterrizaje izquierda',
            valor:fuerza_promedio_aterrizaje_izq,
            u:'N'
        },
        {
            nombre:'Fuerza promedio de aterrizaje derecha',
            valor:fuerza_promedio_aterrizaje_der,
            u:'N'
        },
        {
            nombre:'Fuerza pico de aterrizaje izquierda',
            valor:fuerza_pico_aterrizaje_izq,
            u:'N'
        },
        {
            nombre:'Fuerza pico de aterrizaje derecha',
            valor:fuerza_pico_aterrizaje_der,
            u:'N'
        },
        {
            nombre:'Asimetría de fuerza promedio de aterrizaje Derecha/Izquierda',
            valor:Asimetria_fuerza_frenado_Promedio_Der_Iz,
            u:'%'
        },
        {
            nombre:'Asimetría de fuerza promedio de aterrizaje Izquierda/Derecha',
            valor:Asimetria_fuerza_frenado_Promedio_Iz_Der,
            u:'%'
        },
        {
            nombre:'Asimetría indice de impulso de aterrizaje Derecha/Izquierda',
            valor:Mean(Asimetria_impulso_aterrizaje_index_der_iz),
            u:'%'
        }
        ,
        {
            nombre:'Asimetría indice de impulso de aterrizaje Izquierda/Derecha',
            valor:Mean(Asimetria_impulso_aterrizaje_index_iz_der),
            u:'%'
        },
        {
            nombre:'Impulso Neto promedio de frenado',
            valor:impulso_neto_promedio_de_frenado,
            u:'N*s'
        },
        {
            nombre:'Impulso Neto pico de frenado',
            valor:impulso_neto_pico_de_frenado,
            u:'N*s'
        }
      ]
    }

    // graficarFuerzas(sistema_de_pesos_y_tiempos,[FASE_DESCANSO,FASE_DESCARGA,FASE_FRENADO,FASE_PROPULSION,FASE_VUELO,FASE_ATERRIZAJE])
    // graficarVelocidad(sistema_de_pesos_y_tiempos,velocidades,[FASE_DESCANSO,FASE_DESCARGA,FASE_FRENADO,FASE_PROPULSION,FASE_VUELO,FASE_ATERRIZAJE])
    // graficarDesplazamiento(sistema_de_pesos_y_tiempos,desplazamientos,[FASE_DESCANSO,FASE_DESCARGA,FASE_FRENADO,FASE_PROPULSION,FASE_VUELO,FASE_ATERRIZAJE])
}