// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagina-login{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.pagina-login::before{
    content: "";
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: .4;
    background-image: url('https://images.unsplash.com/photo-1534258936925-c58bed479fcb?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    animation: animar-fondo cubic-bezier(0.075, 0.82, 0.165, 1)  1s forwards;

}

@keyframes animar-fondo {
    to{
        transform: scale(1.2);
    }
}`, "",{"version":3,"sources":["webpack://./src/Paginas/Login/estilo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;IACX,+LAA+L;IAC/L,0BAA0B;IAC1B,4BAA4B;IAC5B,wEAAwE;;AAE5E;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".pagina-login{\n    width: 100vw;\n    height: 100vh;\n    overflow: hidden;\n}\n.pagina-login::before{\n    content: \"\";\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    opacity: .4;\n    background-image: url('https://images.unsplash.com/photo-1534258936925-c58bed479fcb?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');\n    background-size: 100% 100%;\n    background-repeat: no-repeat;\n    animation: animar-fondo cubic-bezier(0.075, 0.82, 0.165, 1)  1s forwards;\n\n}\n\n@keyframes animar-fondo {\n    to{\n        transform: scale(1.2);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
