import { ACELERACION_GRAVEDAD } from "../Constantes";

function Mean(array) {
    const sum = array.reduce((acc, curr) => acc + curr, 0);
    const average = sum / array.length;
    return average;
}

function Sdt(array) {
    const n = array.length
    const mean = Mean(array)
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

function trapz(y, x) {
    let area = 0, areas = [];
    let x_truncados = [];
    for (let i = 0; i < y.length - 1; i++) {
        let base = x[i + 1] - x[i];
        let alturaPromedio = (y[i] + y[i + 1]) / 2;
        area += base * alturaPromedio;
        areas.push(area)
        x_truncados.push((x[i] + x[i + 1]) / 2)
    }
    return [area, areas, x_truncados];
}

function calcularAreaBajoCurva(tiempos, pesos, pesoPromedio) {
    let area = 0;

    for (let i = 0; i < tiempos.length - 1; i++) {
        let base = tiempos[i + 1] - tiempos[i];
        let alturaPromedio = (pesos[i] + pesos[i + 1]) / 2;
        area += base * alturaPromedio;
    }

    return (tiempos[tiempos.length - 1] - tiempos[0]) * pesoPromedio - area;
}

function encontrarIndiceAproximado(tiempos, pesos, areaObjetivo) {
    let areaAcumulada = 0;

    for (let i = 0; i < tiempos.length - 1; i++) {
        let base = tiempos[i + 1] - tiempos[i];
        let alturaPromedio = (pesos[i] + pesos[i + 1]) / 2;
        areaAcumulada += base * alturaPromedio;

        if (areaAcumulada >= areaObjetivo) {
            return i; // Retorna el índice cuando se alcanza o supera el área objetivo
        }
    }

    return -1;
}

function encontrarIndiceAproximadoPeso(tiempos, pesos, pesoPromedio, areaObjetivo) {
    // console.log({
    //     tiempos,
    //     pesos,
    //     pesoPromedio,
    //     areaObjetivo
    // })
    let areaAcumulada = 0, i_anterior = 0;

    for (let i = 0; i < tiempos.length - 1; i++) {
        let base = tiempos[i + 1] - tiempos[i];
        let alturaPromedio = (pesos[i] + pesos[i + 1]) / 2;
        // areaAcumulada += base * alturaPromedio - base * pesoPromedio;
        areaAcumulada += base * Math.abs(alturaPromedio - pesoPromedio)

        if (areaAcumulada >= areaObjetivo) {
            return i_anterior; // Retorna el índice cuando se alcanza o supera el área objetivo
        }
        i_anterior = i
    }

    return -1;
}

function calcularVelocidadCentroDeMasa(velocidad_fase_anteriror, tiempos, pesos_kg, peso_kg) {
    let velocidades = [], velocidad_anterior = 0 + velocidad_fase_anteriror
    const peso_newton = peso_kg * ACELERACION_GRAVEDAD
    const pesos_newton = pesos_kg.map(p => p * ACELERACION_GRAVEDAD)

    for (let i = 0; i < tiempos.length - 1; i++) {
        const v = -ACELERACION_GRAVEDAD * (tiempos[i + 1] - tiempos[i]) + (ACELERACION_GRAVEDAD / peso_newton) * ((pesos_newton[i + 1] + pesos_newton[i]) / 2) * (tiempos[i + 1] - tiempos[i])
        velocidad_anterior += v
        velocidades.push(velocidad_anterior)

    }
    return [velocidades.reduce((a, b) => a + b, 0), velocidades]
}

function calcularVelocidadCentroDeMasaCaida(velocidad_fase_anteriror, tiempos, pesos, pesoPromedio) {
    let velocidades = [], velocidad_anterior = 0 + velocidad_fase_anteriror
    for (let i = 0; i < tiempos.length - 1; i++) {
        const v = -ACELERACION_GRAVEDAD * (tiempos[i + 1] - tiempos[i])
        velocidad_anterior += v
        velocidades.push(velocidad_anterior)
    }
    return [velocidades.reduce((a, b) => a + b, 0), velocidades]
}

function calcularDesplazamientoCentroDeMasa(Desplazamiento_fase_anteriror, tiempos, velocidades) {
    let desplazamientos = [], desplazamiento_anterior = 0 + Desplazamiento_fase_anteriror

    for (let i = 0; i < tiempos.length - 2; i++) {
        const d = ((velocidades[i + 1] + velocidades[i]) / 2) * (tiempos[i + 1] - tiempos[i])
        desplazamiento_anterior += d
        desplazamientos.push(desplazamiento_anterior)

    }
    return [desplazamientos.reduce((a, b) => a + b, 0), desplazamientos]
}

function concentricMaxRFD(tiempos, fuerzas_newton) {
    let rfd = [];

    for (let i = 0; i < tiempos.length - 1; i++) {
        rfd.push((fuerzas_newton[i + 1] - fuerzas_newton[i]) / (tiempos[i + 1] - tiempos[i]))
    }

    return [Math.max(...rfd), rfd]
}

function ConcentricMeanPower(tiempos, trabajo) {
    let cmp = [];

    for (let i = 0; i < tiempos.length - 1; i++) {
        cmp.push((trabajo[i + 1] - trabajo[i]) / (tiempos[i + 1] - tiempos[i]))
    }

    return [Math.max(...cmp), Mean(cmp), cmp]
}

function RPD(tiempos, potencias, masa_persona) {
    const rpds = []
    for (let i = 0; i < potencias.length - 1; i++) {
        const derivada_potencia_tiempo = (potencias[i + 1] - potencias[i]) / (tiempos[i + 1] - tiempos[i])
        rpds.push(derivada_potencia_tiempo)
    }
    return [Math.max(...rpds), Mean(rpds), rpds, rpds.map(r => r / masa_persona)]
}
function obtenerMasas(sistema_de_pesos_y_tiempos, fase) {
    return sistema_de_pesos_y_tiempos.slice(fase.inicio, fase.fin).map(m => m.masa.combinada)
}
function obtenerPesos(sistema_de_pesos_y_tiempos, fase) {
    return sistema_de_pesos_y_tiempos.slice(fase.inicio, fase.fin).map(m => m.peso.combinada)
}
function obtenerPesosIzq(sistema_de_pesos_y_tiempos, fase) {
    return sistema_de_pesos_y_tiempos.slice(fase.inicio, fase.fin).map(m => m.peso.izq)
}
function obtenerPesosDer(sistema_de_pesos_y_tiempos, fase) {
    return sistema_de_pesos_y_tiempos.slice(fase.inicio, fase.fin).map(m => m.peso.der)
}
function obtenerTiempos(sistema_de_pesos_y_tiempos, fase) {
    return sistema_de_pesos_y_tiempos.slice(fase.inicio, fase.fin).map(m => m.tiempo)
}
function derivar(x, y) {
    const derivadas = []
    for (let i = 1; i < y.length - 1; i++) {
        const x_siguiente = x[i + 1]
        const x_actual = x[i]

        const y_siguiente = y[i + 1]
        const y_actual = y[i]

        const delta_x = x_siguiente - x_actual
        const delta_y = y_siguiente - y_actual

        derivadas.push(delta_y / delta_x)
    }
    return derivadas
}
function encontrarIndiceFueraDeLimites(listaNumeros, limiteInferior, limiteSuperior) {
    for (let i = listaNumeros.length - 1; i >= 0; i--) {
        if (listaNumeros[i] > limiteSuperior || listaNumeros[i] < limiteInferior) {
            return i;
        }
    }
    return -1; // Retornar -1 si ningún elemento cumple la condición
}

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }


  const esperar = (t) => {
    return new Promise((resolve) => setTimeout(resolve, t))
}

const canvasToBlob = (canvas) => {
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob)
            return resolve(url)
        })
    })
}

export {
    canvasToBlob,
    esperar,
    blobToBase64,
    trapz,
    calcularAreaBajoCurva,
    encontrarIndiceAproximado,
    encontrarIndiceFueraDeLimites,
    calcularDesplazamientoCentroDeMasa,
    calcularVelocidadCentroDeMasaCaida,
    encontrarIndiceAproximadoPeso,
    Sdt,
    Mean,
    derivar, obtenerTiempos, obtenerPesosDer, obtenerPesosIzq, obtenerPesos, obtenerMasas, RPD, ConcentricMeanPower, concentricMaxRFD, calcularVelocidadCentroDeMasa,
}