import { Breadcrumb, Button, Dropdown, Flex, Form, Table, notification, Select, Col, DatePicker, Divider, Row, Checkbox } from "antd"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import API from "../../../API"
import ReactECharts from 'echarts-for-react';
import { registerTransform } from "echarts/core";
import { transform } from "echarts-stat";

registerTransform(transform.regression)

const Grafica = ({ data }) => {
    const g1 = {
        dataset: [
            {
                source: data?.parametros
            },
            {
                transform: {
                    type: 'ecStat:regression',
                    config: {},
                }
            }
        ],
        title: {
            text: data?.nombre,
            subtext: '',
            left: 'center'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        xAxis: {
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        yAxis: {
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        series: [
            {
                name: 'scatter',
                type: 'scatter',
                datasetIndex: 0
            },
            {
                name: 'line',
                type: 'line',
                smooth: true,
                datasetIndex: 1,
                symbolSize: 0.1,
                symbol: 'circle',
                label: { show: true, fontSize: 16 },
                labelLayout: { dx: -20 },
                encode: { label: 2, tooltip: 1 }
            }
        ]
    }
    return <Col sm={24} md={12} lg={8}><ReactECharts lazyUpdate={true} theme={'dark2'} option={g1} style={{ width: '100%', height: 500 }} /></Col>
}

const AtletaTendencia = () => {
    const [cargando, setCargando] = useState(false)
    const [docs, setDocs] = useState([])
    const [parametros_seleccionados, setParametrosSeleccionados] = useState([])

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/atletas">Atletas</Link>
        },
        {
            title: 'Tendencias'
        }
    ]

    const cargar = async (payload) => {
        try {
            const params = {
                fi: payload.fecha[0].toDate(),
                ff: payload.fecha[1].toDate()
            }
            setCargando(true)
            const { data } = await API('prueba', { params })
            Promise.all(data.docs.map(async (d) => {
                const { data } = await API(`prueba/${d._id}`)
                return { ...d, ...data?.doc }
            })).then((pruebas) => {
                setDocs(pruebas.filter(p => p?.parametros?.length).map(p => p.parametros))
            })
            //setDocs(data.docs)
        } catch (err) {

        } finally {
            setCargando(false)
        }
    }

    const onSelccionarParametro = (checkedValues) => {
        console.log(checkedValues)
        setParametrosSeleccionados(checkedValues)
    }

    const graficas = []

    if (docs.length) {
        for (let i = 0; i < 80; i++) {
            if (parametros_seleccionados.includes(i)) {
                graficas.push({
                    nombre: docs[0][i].nombre,
                    parametros: docs.map((p, y) => {
                        return ([y + 1, p[i].valor])
                    })
                })
            }
        }
    }

    return <div style={{ padding: 32 }}>
        <Breadcrumb items={miga} />
        <br></br>
        <br></br>
        <Form disabled={cargando} layout="vertical" onFinish={cargar}>
            <Form.Item name={'fecha'} label="Rango de fechas" rules={[{ required: true, message: 'Ingrese un rango' }]}>
                <DatePicker.RangePicker />
            </Form.Item>
            <Button loading={cargando} type="primary" htmlType="submit">Filtrar pruebas</Button>
        </Form>
        <Divider></Divider>
        {docs.length > 0 && <Checkbox.Group onChange={onSelccionarParametro}>
            <Row>
                {docs[0].map((d, i) => <Col xs={12} md={8}><Checkbox value={i}>{d.nombre}</Checkbox></Col>)}
            </Row>
        </Checkbox.Group>}
        <Divider />
        <Row gutter={[16, 16]}>
            {graficas?.map((g, i) => <Grafica data={g} key={i} />)}
        </Row>



    </div>
}

export default AtletaTendencia