import { useEffect, useRef, useState } from "react"
import Contexto from "./Ctx"
import { Button, Flex, Modal, Spin, Typography } from "antd"
import { EVENT_BLE } from "../../Constantes"
import { LoadingOutlined } from "@ant-design/icons"

const BleProveedor = ({ children }) => {
    const [dispositivo, setDispositivo] = useState(null)
    const [cargando, setCargando] = useState(false)
    const [data, setData] = useState([])
    const caracteristica_resetear = useRef(null)

    const uuid_service = '00000001-710e-4a5b-8d75-3e5b444bc3cf'
    const uuid_caracteristica_peso = '00000002-710e-4a5b-8d75-3e5b444bc3cf'
    const uuid_caracteristica_resetear = '00000003-710e-4a5b-8d75-3e5b444bc3cf'




    const onPeso = (event) => {
        const peso = new TextDecoder().decode(event.target.value);
        const _data = peso.split(",")
        console.log(_data[0])
        const disparador = new CustomEvent(EVENT_BLE, { detail: [parseFloat(_data[0]), parseFloat(_data[1]), parseFloat(_data[2])] });
        document.dispatchEvent(disparador);
        //setData([parseFloat(_data[0]),parseFloat(_data[1]),parseFloat(_data[2])])
    }

    async function resetearBalanza() {
        if (caracteristica_resetear.current) {
            try {
                let encoder = new TextEncoder('utf-8');
                await caracteristica_resetear.current.writeValue(encoder.encode('R'));
            } catch (err) {
                console.log(err)
            }
        }
    }

    const conectar = async () => {
        try {
            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true,

                 //filters: [{ name: 'raspberrypi' }],
                 optionalServices: [uuid_service]
            });
            setCargando(true)
            const server = await device.gatt.connect()
            const servicio = await server.getPrimaryService(uuid_service);
            const caracteristica = await servicio.getCharacteristic(uuid_caracteristica_peso)

            caracteristica.startNotifications().then(_ => {
                caracteristica.addEventListener('characteristicvaluechanged', onPeso);
            })
            //caracteristica_resetear.current = await servicio.getCharacteristic(uuid_caracteristica_resetear)


            setDispositivo(device)

        } catch (err) {
            console.log(err)
        } finally {
            setCargando(false)
        }
    }

    useEffect(() => {

    }, [])

    const value = {
        data,
        resetearBalanza
    }

    return <Contexto.Provider value={value}>
        <Modal open={!dispositivo} title="Conectar dispositivo" onCancel={() => setDispositivo(1)} footer={[]}>
            <center>
                {cargando ? <Flex vertical>
                    <Spin spinning={true} indicator={<LoadingOutlined />}></Spin>
                    <Typography.Text>Conectando...</Typography.Text>
                </Flex> : <Button onClick={conectar}>Conectar</Button>}
            </center>
        </Modal>
        {children}
    </Contexto.Provider>
}

export default BleProveedor