import { Breadcrumb, Button, Dropdown, Flex, Form, Table,notification,Select } from "antd"
import { ClockCircleOutlined, DeleteOutlined, DotChartOutlined, DownOutlined, EditOutlined, PlusOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import API from "../../../API";

const AtletaListado = () => {
    const navigate = useNavigate()
    const [instituciones, setInstituciones] = useState([])
    const [docs, setDocs] = useState([])
    const [cargando, setCargando] = useState(false)

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: 'Atletas'
        }
    ]

    const menu = [
        {
            label: 'Editar',
            key: 1,
            icon: <EditOutlined />
        },
        {
            label: 'Iniciar CMJ',
            key: 2,
            icon: <ClockCircleOutlined />
        },
        {
            label: 'Iniciar Nordic Curl',
            key: 3,
            icon: <ClockCircleOutlined />
        },
        {
            label: 'Tendencias',
            key: 4,
            icon: <DotChartOutlined />
        },
        {
            label: 'Borrar',
            key: 5,
            icon: <DeleteOutlined />,
            danger: true
        }
    ]

    const Acciones = ({ atleta }) => {
        const click = ({ key }) => {

            if (key == '1') {
                navigate(`/atleta/${atleta._id}/actualizar`)
                return
            }

            if (key == '2') {
                navigate(`/atleta/${atleta._id}/iniciar-prueba/cmj`)
                return
            }

            if (key == '3') {
                navigate(`/atleta/${atleta._id}/iniciar-prueba/nordic`)
                return
            }

            if (key == '4') {
                navigate(`/atleta/${atleta._id}/tendencias`)
                return
            }
            if (key =='5'){
                eliminar(atleta._id)
                return
            }
        }
        return <Dropdown.Button icon={<DownOutlined />} menu={{ items: menu, onClick: click }}>Acciones</Dropdown.Button>
    }

    const cargarIns = async () => {
        try {
            const { data } = await API(`institucion`)
            setInstituciones(data.docs)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
            navigate('/atletas')
        }
    }

    const cargar = async (params) => {
        try {
            setCargando(true)
            const { data } = await API('atleta',{params})
            
            setDocs(data.docs)
        } catch (err) {

        } finally {
            setCargando(false)
        }
    }

    const eliminar=async (id)=>{
        try{
            setCargando(true)
            const url = `atleta/${id}`
            const method = "delete"
            console.log(url)
            await API({
                url,
                method
            })
        }catch(err){
            console.log(err)
        }finally{
            setCargando(false)
            cargar()
        }
    }

    useEffect(() => {
        cargarIns()
        cargar({})
    }, [])

    return <div style={{ padding: 32 }}>
        <Flex gap="middle" align="center">
            <Breadcrumb items={miga} />
            <Link to='/atleta/guardar'><Button icon={<PlusOutlined />} /></Link>
        </Flex>
        <br></br>
        <Form onFinish={cargar}>
            <Form.Item name={'institucion'} label="Institución" >
                <Select allowClear options={instituciones.map(i => ({ value: i._id, label: i.nombre }))} />
            </Form.Item>
            <Button type="primary" htmlType="submit">Filtrar</Button>
        </Form>
        <br></br>
        <Table dataSource={docs} pagination={false}>
            <Table.Column title="Nombre" dataIndex={'nombre'} />
            <Table.Column title="Ide." dataIndex={'ide'} />
            <Table.Column title="Peso" dataIndex={'peso'} />
            <Table.Column title="Acciones" render={(d) => (<Acciones atleta={d} />)} />
        </Table>
    </div>
}

export default AtletaListado