import { Breadcrumb, Button, Dropdown, Flex, Table } from "antd"
import { ClockCircleOutlined, DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { useState,useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import API from "../../../API";

const InstitucionListado=()=>{
    const navigate=useNavigate()

    const [docs,setDocs] = useState([])
    const [cargando,setCargando] = useState(false)

    const miga=[
        {
            title:<Link to="/">Inicio</Link>
        },
        {
            title:'Instituciones'
        }
    ]

    const menu=[
        {
            label:'Editar',
            key:1,
            icon:<EditOutlined/>
        },
        {
            label:'Borrar',
            key:3,
            icon:<DeleteOutlined/>,
            danger:true
        }
    ]

    const Acciones=({ins})=>{
        const click=({key})=>{
            if(key=='1'){
                navigate(`/institucion/${ins._id}`)
                return
            }
            else if(key=='3'){
                {eliminar(ins._id)}
                return
            }
        }
        return <Dropdown.Button icon={<DownOutlined/>} menu={{items:menu,onClick:click}}>Acciones</Dropdown.Button>
    }

    const cargar=async ()=>{
        try{
            setCargando(true)
            const {data} = await API('institucion')
            setDocs(data.docs)
        }catch(err){

        }finally{
            setCargando(false)
        }
    }

    const eliminar=async (id)=>{
        try{
            setCargando(true)
            const url = `institucion/${id}`
            const method = "delete"
            console.log(url)
            await API({
                url,
                method
            })
        }catch(err){
            console.log(err)
        }finally{
            setCargando(false)
            cargar()
        }
    }

    useEffect(()=>{
        cargar()
    },[])

    return <div style={{padding:32}}>
        <Flex gap="middle" align="center">
            <Breadcrumb items={miga} />
            <Link to='/institucion/guardar'><Button icon={<PlusOutlined/>} /></Link>
        </Flex>
        <br></br>
        <Table dataSource={docs} pagination={false}>
            <Table.Column title="Nombre" dataIndex={'nombre'}/>
            <Table.Column title="Acciones" render={(d)=>(<Acciones ins={d}/>)}/>
        </Table>
    </div>
}

export default InstitucionListado