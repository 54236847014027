import { Button, Flex } from "antd"
import { Link } from "react-router-dom"
import CrearGrupoModal from "./Modal"
import { useState } from "react"
import PruebaCuadrante from "../../../Cuadrante"

const CrearGrupo=({registros})=>{
    const [abrir,setAbrir] = useState(false)

    
    if(!registros?.length){
        return null
    }

    return <>
    <CrearGrupoModal abrir={abrir} cerrar={setAbrir} registros={registros}/>
    <Flex gap="middle">
    <Button type="primary" onClick={()=>setAbrir(true)}>Crear muestra</Button>
    <Link to={`/prueba/cuadrante/${registros}`}><Button type="primary" htmlType="button" >Reporte de Cuadrante</Button></Link>
    </Flex>
    
    </>
}

export default CrearGrupo