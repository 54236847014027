import { Breadcrumb, Button, Card, Flex, Form, Input, notification } from "antd"
import { useState,useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import API from "../../../API";

const {useForm} = Form
const InstitucionGuardar=()=>{
    const navigate=useNavigate()
    const {id} = useParams()
    const [cargando,setCargando] = useState(false)
    const [form] = useForm()

    const miga=[
        {
            title:<Link to="/">Inicio</Link>
        },
        {
            title:<Link to="/instituciones">Instituciones</Link>
        },
        {
            title:id ? 'Actualizar institución' : 'Registrar nueva institución'
        }
    ]

    const guardar=async (data)=>{
        try{
            setCargando(true)
            const url = id ? `institucion/${id}` : 'institucion/'
            const method = id ? "patch" : "post"
            await API({
                url,
                method,
                data
            })
           
            navigate('/instituciones')
        }catch(error){

        }finally{
            setCargando(false)
        }
    }

    const cargar=async ()=>{
        try{
            setCargando(true)
            const {data} = await API(`institucion/${id}`)
            form.setFieldsValue(data.doc)
        }catch(error){
            notification.error({description:error.response.data.mensaje})
            navigate('/instituciones')
        }finally{
            setCargando(false)
        }
    }

    useEffect(()=>{
        if(id){
            cargar()
        }
    },[id])
    
    return <div style={{padding:32}}>
        <Breadcrumb items={miga} />
        <br></br>
        <Flex align="center" justify="center" >
            <Card style={{width:'90%',maxWidth:600}}>
                <Form layout="vertical" onFinish={guardar}>
                    <Form.Item name={'nombre'} label="Nombre" rules={[{required:true,message:'Campo requerido'}]}>
                        <Input/>
                    </Form.Item>
                    
                    <Button loading={cargando} type="primary" htmlType="submit" block>Guardar</Button>
                </Form>
            </Card>
        </Flex>
    </div>
}

export default InstitucionGuardar
