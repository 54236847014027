import { Layout } from "antd"
import { Outlet } from "react-router-dom"
import Sesion from "../../Contexts/Sesion"

const Base=()=>{
    return <Sesion.SesionProveedor>
    <Layout style={{minHeight:'100vh'}}>
        <Outlet/>
    </Layout>
</Sesion.SesionProveedor>
   
}

export default Base