import { BackwardOutlined, DashboardOutlined, LeftOutlined, LineChartOutlined, PlayCircleOutlined, PlusOutlined, StopOutlined, VideoCameraOutlined } from "@ant-design/icons"
import { Badge, Button, Card, Flex, Layout, Progress, Segmented, Spin, Table, Tabs, Typography, notification } from "antd"
import { useEffect, useRef, useState } from "react";



import Rep from "./Rep";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../API";


const { Header } = Layout




const NordicGuardar = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [reps,setReps]=useState([])
    const [cargando,setCargando]=useState(false)
    const [atleta,setAtleta] = useState({})
    const [rep_actual,setRepActual] = useState(-1)

    const agregarRep=()=>{
        setReps(prev=>{
            return [...prev,{label:`Rep. ${prev.length}`,key:prev.length,children:<Rep atleta={atleta?._id}/>}]
        })
        setRepActual(reps.length)
    }

    const borrarRep = (targetKey) => {
        let newActiveKey = rep_actual;
        let lastIndex = -1;
        reps.forEach((item, i) => {
          if (item.key === targetKey) {
            lastIndex = i - 1;
          }
        });
        const newPanes = reps.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
          if (lastIndex >= 0) {
            newActiveKey = newPanes[lastIndex].key;
          } else {
            newActiveKey = newPanes[0].key;
          }
        }
        setReps(newPanes);
        setRepActual(newActiveKey);
      };

    const onEdit = (targetKey, action) => {
        if (action === 'add') {
            agregarRep();
        } else {
            borrarRep(targetKey);
          }
    };

    const cargarAtleta=async ()=>{
        try{
            setCargando(true)
            const {data} = await API(`atleta/${id}`)
            setAtleta(data.doc)
        }catch(error){
            notification.error({description:error.response.data.mensaje})
            navigate('/atletas')
        }finally{
            setCargando(false)
        }
    }

    useEffect(()=>{
        
        cargarAtleta()
    },[])

    useEffect(()=>{
        if(atleta?._id){
            agregarRep()
        }
    },[atleta])
   


    return <Flex vertical style={{ flex: 1 }}>
        <Header>
            <Flex gap="middle" justify="space-between" align="center" style={{ height: '100%' }}>
                <Flex gap="middle">
                    <LeftOutlined onClick={()=>navigate(-1)}/>
                    <Typography.Text>{atleta?.nombre}</Typography.Text>
                    <Typography.Text type="secondary">{atleta?.peso}kg {(atleta?.peso/0.45359237).toFixed(2)}lbs</Typography.Text>
                </Flex>
                <Typography.Title level={5}>Curl Nórdico</Typography.Title>
                <Flex gap="middle" justify="center" align="center">
                    <img width={16} src="/imagenes/bateria.png" />
                    <div style={{ width: 150 }}>
                        <Progress percent={40} status="active" steps={10} size="small" />
                    </div>
                </Flex>
            </Flex>
        </Header>
        <Tabs
            style={{paddingTop:8}}
            type="editable-card"
            items={reps}
            onChange={setRepActual}
            onEdit={onEdit}
            activeKey={rep_actual}
        />
    </Flex>
}

export default NordicGuardar